import * as React from "react";
const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={120}
    height={120}
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_135_201)">
      <path
        d="M60.0034 0C26.9162 0 0 26.9162 0 60.0034C0 93.0905 26.9162 120.007 60.0034 120.007C93.0905 120.007 120.007 93.0905 120.007 60.0034C120.007 26.9162 93.0838 0 60.0034 0ZM19.7538 43.3316L43.3316 19.7538H76.6751L100.253 43.3316V76.6751L76.6751 100.253H43.3316L19.7538 76.6751V43.3316ZM18.405 75.3263L3.08211 60.0034L18.405 44.6805V75.3263ZM101.595 42.7719V19.4503L101.966 19.0794C112.244 29.6139 118.597 43.9926 118.644 59.828L101.595 42.7786V42.7719ZM116.918 60.0034L101.595 75.3263V44.6805L116.918 60.0034ZM78.577 19.7538H99.3896L88.9833 30.1602L78.577 19.7538ZM100.246 20.7992V41.423L89.9342 31.1111L100.246 20.7992ZM100.738 18.405H77.2281L60.1787 1.34885C76.0614 1.39605 90.4738 7.78958 101.022 18.1217L100.738 18.405ZM75.3263 18.405H44.6805L60.0034 3.08211L75.3263 18.405ZM42.7719 18.405H19.3559L19.0255 18.0745C29.5667 7.77609 43.9656 1.39605 59.8213 1.34885L42.7719 18.405ZM31.0639 30.113L20.7048 19.7538H41.423L31.0639 30.113ZM30.113 31.0639L19.7538 41.423V20.7115L30.113 31.0706V31.0639ZM88.9361 89.8938L99.2952 100.253H78.577L88.9361 89.8938ZM89.8938 88.9361L100.253 78.577V99.2952L89.8938 88.9361ZM75.3263 101.602L60.0034 116.925L44.6805 101.602H75.3263ZM41.423 100.253H20.7992L31.1111 89.941L41.423 100.253ZM30.1602 88.9833L19.7538 99.3896V78.5837L30.1602 88.9901V88.9833ZM19.4503 101.602H42.7719L59.8213 118.658C43.9858 118.611 29.6139 112.258 19.0727 101.979L19.4436 101.609L19.4503 101.602ZM77.2281 101.602H100.644L100.975 101.932C90.4333 112.237 76.0411 118.611 60.1787 118.658L77.2281 101.602ZM101.595 100.644V77.2281L118.644 60.1787C118.597 76.0344 112.224 90.4333 101.919 100.975L101.588 100.644H101.595ZM18.0745 19.0322L18.405 19.3627V42.7719L1.34885 59.8213C1.39605 43.9656 7.76935 29.5667 18.0745 19.0255V19.0322ZM1.34885 60.1787L18.3982 77.2349V100.738L18.115 101.022C7.78284 90.4738 1.38931 76.0614 1.3421 60.1787H1.34885Z"
        fill="#FFDF76"
      />
      <path
        d="M29.9646 60.6777H59.3222V90.0353C58.4657 90.3186 57.8385 91.1212 57.8385 92.0721C57.8385 93.2658 58.8029 94.2303 59.9966 94.2303C61.1904 94.2303 62.1548 93.2658 62.1548 92.0721C62.1548 91.1144 61.5276 90.3186 60.6711 90.0353V60.6777H90.0287C90.3119 61.5343 91.1145 62.1615 92.0654 62.1615C93.2591 62.1615 94.2236 61.197 94.2236 60.0033C94.2236 58.8096 93.2591 57.8452 92.0654 57.8452C91.1145 57.8452 90.3119 58.4656 90.0287 59.3289H60.6711V29.9713C61.5276 29.688 62.1548 28.8855 62.1548 27.9345C62.1548 26.7408 61.1904 25.7764 59.9966 25.7764C58.8029 25.7764 57.8385 26.7408 57.8385 27.9345C57.8385 28.8922 58.4657 29.688 59.3222 29.9713V59.3289H29.9646C29.6813 58.4724 28.8788 57.8452 27.9278 57.8452C26.7341 57.8452 25.7697 58.8096 25.7697 60.0033C25.7697 61.197 26.7341 62.1615 27.9278 62.1615C28.8788 62.1615 29.6813 61.541 29.9646 60.6777Z"
        fill="#FFDF76"
      />
    </g>
    <defs>
      <clipPath id="clip0_135_201">
        <rect width={120} height={120} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SVGComponent;
