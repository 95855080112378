import React from 'react';
import { SnackbarProvider } from 'notistack';
import { Routes, Route, Navigate } from 'react-router';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Notifier } from './components/notifier';
import './App.css';
import { DashboardLayout } from './components/layout';
import { Login } from './pages/login';
import { observer } from 'mobx-react';
import { Profile } from './pages/profile';
import { GetMyth } from './pages/getMyth';
import { About } from './pages/about';
import { SnackbarCustom } from './components/SnackbarCustom';

export const App = observer(() => {
  return (
    <SnackbarProvider maxSnack={5} autoHideDuration={4000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} Components={{
      default: SnackbarCustom,
    }}>
      <GoogleOAuthProvider clientId="674134794142-u920be709ss306ljrfsfuit0mr200vo2.apps.googleusercontent.com">
        <Routes>
        {/* <AuthLayout /> */}
          <Route path="/" element={<DashboardLayout />}>
            <Route path="" element={<Navigate to="getMyth" />} />
            <Route path="login" element={<Login />} />
            <Route path="getMyth" element={<GetMyth/>} />
            <Route path="about" element={<About/>} />
            <Route path="profile" element={<Profile/>} />
            <Route path="*" element={<Navigate to="getMyth"/>} />
          </Route>
        </Routes>
        <Notifier />
      </GoogleOAuthProvider>
    </SnackbarProvider>
  );
});
