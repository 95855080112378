import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      style={{ marginRight: '10px' }}
    >
      <g>
        <path
          stroke="#21D9F7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8 7.833v3.334M8 14.5a6 6 0 110-12 6 6 0 010 12zm.033-8.667V5.9h-.066v-.067h.066z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
