import * as React from "react";
const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="path-1-inside-1_2001_2313" fill="white">
      <path d="M11.727 16.4425C11.326 16.8635 10.651 16.8545 10.261 16.4235L6.86899 12.6685C6.55499 12.3195 6.58199 11.7825 6.92999 11.4675C7.27999 11.1525 7.81699 11.1825 8.13099 11.5285L11.017 14.7235L16.885 8.51255C17.208 8.17155 17.746 8.15855 18.086 8.48355C18.426 8.80755 18.44 9.34555 18.115 9.68555L11.727 16.4425Z" />
    </mask>
    <path
      d="M11.727 16.4425C11.326 16.8635 10.651 16.8545 10.261 16.4235L6.86899 12.6685C6.55499 12.3195 6.58199 11.7825 6.92999 11.4675C7.27999 11.1525 7.81699 11.1825 8.13099 11.5285L11.017 14.7235L16.885 8.51255C17.208 8.17155 17.746 8.15855 18.086 8.48355C18.426 8.80755 18.44 9.34555 18.115 9.68555L11.727 16.4425Z"
      fill="#F4FDFF"
    />
    <path
      d="M11.727 16.4425L13.1752 17.822L13.1803 17.8165L11.727 16.4425ZM10.261 16.4235L8.77686 17.7642L8.778 17.7655L10.261 16.4235ZM6.86899 12.6685L5.38219 14.0062L5.38486 14.0092L6.86899 12.6685ZM6.92999 11.4675L5.59206 9.98095L5.58783 9.98478L6.92999 11.4675ZM8.13099 11.5285L9.61516 10.1879L9.61203 10.1845L8.13099 11.5285ZM11.017 14.7235L9.53283 16.0642L10.984 17.6707L12.4708 16.097L11.017 14.7235ZM16.885 8.51255L15.433 7.13717L15.4312 7.13904L16.885 8.51255ZM18.086 8.48355L16.704 9.92929L16.7063 9.93142L18.086 8.48355ZM18.115 9.68555L16.6692 8.30356L16.6617 8.31157L18.115 9.68555ZM10.2788 15.0631C10.6807 14.6412 11.3544 14.6511 11.744 15.0816L8.778 17.7655C9.94756 19.058 11.9713 19.0859 13.1752 17.8219L10.2788 15.0631ZM11.7451 15.0829L8.35312 11.3279L5.38486 14.0092L8.77686 17.7642L11.7451 15.0829ZM8.35579 11.3309C8.77738 11.7994 8.74313 12.524 8.27215 12.9503L5.58783 9.98478C4.42086 11.0411 4.33261 12.8397 5.38219 14.0062L8.35579 11.3309ZM8.26792 12.9541C7.79428 13.3804 7.07154 13.3372 6.64995 12.8726L9.61203 10.1845C8.56244 9.02792 6.7657 8.92468 5.59206 9.98096L8.26792 12.9541ZM6.64683 12.8692L9.53283 16.0642L12.5012 13.3829L9.61515 10.1879L6.64683 12.8692ZM12.4708 16.097L18.3388 9.88605L15.4312 7.13904L9.56321 13.35L12.4708 16.097ZM18.337 9.88792C17.8962 10.3533 17.1632 10.3682 16.704 9.92929L19.468 7.0378C18.3288 5.94888 16.5198 5.9898 15.433 7.13717L18.337 9.88792ZM16.7063 9.93142C16.2495 9.49619 16.2266 8.76669 16.6692 8.30358L19.5607 11.0675C20.6534 9.9244 20.6025 8.1189 19.4657 7.03567L16.7063 9.93142ZM16.6617 8.31157L10.2737 15.0686L13.1803 17.8165L19.5683 11.0595L16.6617 8.31157Z"
      fill="#F4FDFF"
      mask="url(#path-1-inside-1_2001_2313)"
    />
  </svg>
);
export default SVGComponent;
