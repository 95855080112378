import React from "react";

function CopyIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 17"
      {...props}
    >
      <path
        fill="#21D9F7"
        fillRule="evenodd"
        d="M12 4.5v5.334A2.667 2.667 0 019.333 12.5H4a2.667 2.667 0 01-2.667-2.666V4.5A2.667 2.667 0 014 1.833h5.333A2.667 2.667 0 0112 4.5zm-9.667 0v5.334A1.667 1.667 0 004 11.5h5.333c.92 0 1.667-.746 1.667-1.666V4.5c0-.92-.746-1.667-1.667-1.667H4c-.92 0-1.667.747-1.667 1.667z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#21D9F7"
        d="M14 6a.507.507 0 00-.5.5v5.334A2.173 2.173 0 0111.333 14H6a.5.5 0 100 1h5.333a3.167 3.167 0 003.167-3.166V6.5A.507.507 0 0014 6z"
      ></path>
    </svg>
  );
}

export default CopyIcon;
