import {Outlet, useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react';
import {useStores} from '../../stores/app';
import styles from './dashboard.module.css';
import ProfileIcon from '../../app/icons/profileIcon';
import React, { useEffect, useState } from 'react';
import { IconButton } from '../IconBtn';
import DiscordIcon from '../../app/icons/discordIcon';
import TwitterIcon from '../../app/icons/TwitterIcon';
import { HeaderRoutLabel } from '../HeaderRoutLabel';
import { useLocation } from 'react-router-dom';
import { Modal } from '../Modal';
import { RegistrationLoginModal } from '../RegistrationLoginModal';
import { ResetPasswordModal } from '../ResetPasswordModal';

export const DashboardLayout = observer(() => {
  const { authStore } = useStores();
  const location = useLocation();
  const navigate = useNavigate();
  const [currScrollY, setCurrScrollY] = useState(window.scrollY);
  const header = document.getElementById('header');

  const profileIconClick = () => {
    if (authStore.isLoggedIn) {
      navigate('/profile');
    } else {
      authStore.showLastModal();
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", logHeight);
  
    return () => { // return a cleanup function to unregister our function since it will run multiple times
      window.removeEventListener("scroll", logHeight);
    };
  }, []);

  const logHeight = () => {
    setCurrScrollY(window.scrollY);
  }

  return (
    <div className={styles.mainContaner}>

      {location.pathname === '/about' &&
      <div className={styles.aboutHeaderExtension}>
        <img src="/images/aboutBG.png" className={styles.aboutBg}></img>
        <h1>MYTHTOPIA<img src="/images/aboutTitleBF.png" className={styles.aboutTitleBF}></img></h1>
        <h5>discover the myths in real</h5>
      </div>}
      
      <div style={{ height: "31px" }}></div>

      <div id="header" style={{ display: 'flex', justifyContent: 'center', width: '100%'}} className={`${styles.header} ${currScrollY >= (header?.offsetTop || 0) - 1 ? styles.headerBackground : styles.headerBackgroundDisable}`}>

        <div style={{ width: '1280px', display: 'flex', justifyContent: 'space-between', padding: '20px 0px 20px 0px', alignItems: 'center' }}>
          <div className={styles.btnsContainer}>
            <HeaderRoutLabel label='Myths' currPath={location.pathname} redirectTo='/getMyth'/>
            <HeaderRoutLabel label='Legends' currPath={location.pathname} redirectTo='/legends'/>
            <HeaderRoutLabel label='Glamping' currPath={location.pathname} redirectTo='/glamping'/>
          </div>

          <div className={styles.btnsContainer}>
            <HeaderRoutLabel label='About' currPath={location.pathname} redirectTo='/about'/>
            <HeaderRoutLabel label='Contacts' currPath={location.pathname} redirectTo='/contacts'/>
            <div onClick={profileIconClick} style={{ cursor: 'pointer' }}><ProfileIcon/></div>
          </div>
        </div>
      </div>

      <div style={{ height: "31px" }}></div>
        <Outlet/>
        <div style={{ width: '1280px'}}>
          <div style={{ marginTop: '100px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src='/images/mainLogoSquare.png'></img>
              <h1 style={{ color: 'white', fontFamily: 'Anthu', fontSize: '90px', margin: '0px 0px 0px 40px', fontWeight: '500', padding: 0}}>MYTHTOPIA</h1>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton icon={<DiscordIcon/>} onClick={() => window.open("https://www.discord.com/users/Mythtopia#3173", "_blank", "noreferrer")}/>
              <IconButton icon={<TwitterIcon/>} style={{ marginLeft: '20px' }} onClick={() => window.open("https://x.com/mythtopiaNFT", "_blank", "noreferrer")}/>
            </div>
          </div>
          <div style={{ color: 'white', opacity: '.7', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', fontSize: '30px', marginTop: '60px' }}>
            <p>Myths</p>
            <p>Legends</p>
            <p>Glamping</p>
            <p>Oxe token</p>
            <p>About</p>
            <p>Contacts</p>
          </div>
          <div style={{ color: 'white', opacity: '.7', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', fontSize: '30px' }}>
            <p>Privacy Policy</p>
            <p>FAQ</p>
          </div>
          <div style={{ color: 'white', opacity: '.7', display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '15px', marginTop: '70px' }}>
            <p>© 2024 Mythtopia Resort - All Rights Reserved</p>
            <p>Powered by Myths and Legends</p>
            <div style={{ display: 'flex' }}><p style={{ opacity: .3}}>{"Home > "}</p><p style={{ marginLeft: '4px'}}>Account</p></div>
          </div>
        </div>
        <Modal isShow={authStore.showModalLogin}>
          <RegistrationLoginModal onClose={() => authStore.closeModal()}/>
        </Modal>
        <Modal isShow={authStore.isShowForgotPassword}>
          <ResetPasswordModal onClose={() => authStore.toggleShowPassword()}/>
        </Modal>
    </div>
  );
});
