import React from 'react';
import { observer } from 'mobx-react';
import styles from './DeletePhotoModal.module.css';
import CloseIcon from '../../app/icons/CloseIcon';
import { Button, ButtonType } from '../Button';
import { useStores } from '../../stores/app';

interface DeletePhotoModalProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClose?: () => void;
}

export const DeletePhotoModal: React.FC<DeletePhotoModalProps> = observer((props) => {
  const { authStore } = useStores();


  return (
    <div className={styles.container}>
      <div>
        <h2 className={styles.modalTitle}>Delete photo?</h2>
        <CloseIcon className={styles.crossIcon} onClick={props.onClose}/>
      </div>
      <p className={styles.description}>Are you sure you want to delete your avatar?</p>
      <form onSubmit={(e) => { e.preventDefault(); }} className={styles.inputContainer}>
        <div className={styles.btnContainer}>
          <Button text='Cancel' fullWidth btnStyle={ButtonType.outline} onClick={props.onClose}/>
          <Button text='Delete' fullWidth btnStyle={ButtonType.bright} onClick={() => {authStore.setUserImage(null); props.onClose && props.onClose();}}/>
        </div>
      </form>
    </div>
  );
});