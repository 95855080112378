import React from 'react';
import { Tooltip } from 'react-tooltip';
import styles from './Input.module.css';
import EyeIcon from '../../app/icons/EyeIcon';
import CheckIcon from '../../app/icons/CheckIcon';
import ErrorInfoIcon from '../../app/icons/errorInfoIcon';
import CrossEyeIcon from '../../app/icons/CrossEyeIcon';

const sizeMap = {
  "small": styles.defaultSmall,
  "medium": styles.defaultMid,
  "large": styles.defaultLarge,
};

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeHolder?: string;
  isActive?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  inpSize?: 'small' | 'medium' | 'large';
  errorMsg?: string;
  keyj?: string;
}

export const Input: React.FC<InputProps> = (props) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(props.checked);

  const handleOnClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (props.type === "checkbox") setIsChecked((e.target as HTMLInputElement).checked);
    if (props.onClick) props.onClick(e);
  }

  if (props.type === 'radio') {
    return (
    <div className={styles.radio}>
      <input {...props}/>
    </div>
    );
  }

  if (props.type === 'switch') {
    return (
      <label className={styles.switch}>
        <input type="checkbox" onChange={props.onChange} checked={props.checked}/>
        <span className={`${styles.slider} ${styles.round}`}></span>
      </label>
    );
  } else if (props.type === "checkbox") {
    return (
      <div className={styles.checkContainer} style={props.style}>
        <input {...props} onClick={handleOnClick} className={`${styles.default} ${sizeMap[props.inpSize || 'medium']} ${props.className}`} type={props.type === 'password' && showPassword ? 'text' : props.type}/>
        {props.type === "checkbox" && isChecked && (<CheckIcon className={styles.checkIcon}/>)}
      </div>
    );
  } else {
    return (
      <div className={`${styles.container} ${props.errorMsg ? styles.errorBorder : styles.defBorder}`} style={props.style}>
        <input {...props} onClick={handleOnClick} className={` ${styles.default} ${sizeMap[props.inpSize || 'medium']} ${props.className}`} type={props.type === 'password' && showPassword ? 'text' : props.type}/>
        {props.errorMsg && props.type !== 'password' && <ErrorInfoIcon id={`errorMsg${props.keyj}`} style={{ width: 24, height: 24 }}/>}
        {props.type === 'password' && 
          (!showPassword ? (
            <EyeIcon onClick={(e) => {e.preventDefault(); setShowPassword(!showPassword);}} className={styles.passwordEye} />) : (
            <CrossEyeIcon onClick={(e) => {e.preventDefault(); setShowPassword(!showPassword);}} className={styles.passwordEye} />
            ))
        }
        <Tooltip anchorSelect={`#errorMsg${props.keyj}`} place="top">
          {props.errorMsg}
        </Tooltip>
      </div>
    );
  }
};