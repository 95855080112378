import * as React from "react";
const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.9334 19.4001L19.4134 27.9201C17.5467 29.7868 14.48 29.7868 12.6 27.9201L4.08002 19.4001C2.21336 17.5334 2.21336 14.4668 4.08002 12.5868L12.6 4.06675C14.4667 2.20008 17.5334 2.20008 19.4134 4.06675L27.9334 12.5868C29.8 14.4668 29.8 17.5334 27.9334 19.4001Z"
      stroke="#FFDF76"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.33334 8.33325L23.6667 23.6666"
      stroke="#FFDF76"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.6667 8.33325L8.33334 23.6666"
      stroke="#FFDF76"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SVGComponent;