import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import styles from './ShowAttributesModal.module.css';
import CloseIcon from '../../app/icons/CloseIcon';
import { useStores } from '../../stores/app';
import { UnknownObject } from '../../core';

interface ShowAttributesModalProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClose?: () => void;
  tokenId: number;
}

const attributeMap: {[key: string]: {[key: string]: string}} = {
  'runes': {
    '1': '20% discount (extra people, food, & merch)',
    '2': '15% discount (extra people, food, & merch)',
    '3': '12% discount (extra people, food, & merch)',
    '4': '10% discount (extra people, food, & merch)',
    '5': '8% discount (extra people, food, & merch)',
    '6': '5% discount (extra people, food, & merch)',
    '7': '3% discount (extra people, food, & merch)',
    '8': 'none',
  },
  "season": {
    '1': 'Spring - stay for minimum 2 nights p/y ',
    '2': 'Summer - stay for minimum 2 nights p/y + Event ticket x 2',
    '3': 'Autumn - stay for upto 3 nights p/y (min stay 2 nights) ',
    '4': 'Winter - stay for upto 3 nights p/y (min stay 2 nights) + Event ticket x 2',
  },
  "creature": {
    '1': 'Frog - 8% return on staking ',
    '2': 'Wolf - 9% return on staking ',
    '3': 'Snake - 10% return on staking ',
    '4': 'Stag - 11% return on staking ',
    '5': 'April - 12% return on staking ',
    '6': 'Unicorn - 13% return on staking ',
    '7': 'None.',
  },
  "companion": {
    '1': 'Wood Horn - Medium dome (7m) ',
    '2': 'Cliff face - Medium dome (7m) + breakfast',
    '3': 'Druid/ Wizard - Medium dome (7m) + pizza 1 night',
    '4': 'Bran - Medium dome (7m) + breakfast + Pizza 1 night',
    '5': 'Scrounge - Large dome (8m) + breakfast',
    '6': 'WAB - Large dome (8m) +breakfast + Pizza 1 night',
  },
  "foreground": {
    '1': 'Runnig Water - Fire wood x1, Audiobook x1 ',
    '2': 'Glowing Rocks - Fire wood x2, Audiobook x1 ',
    '3': 'Neon Steps - 3d printed figure x1, Audiobook x2, Fire wood x1',
    '4': 'Glowing Wood - 3d printed figure x2, Audiobook x2, Fire wood x2',
    '5': 'Shining grass - 3d printed figure x2, Audiobook x2, Fire wood x2 + Activity bonus = 0.0025 OXE',
    '6': 'Fire stone - 3d printed figure x2, Audiobook x2, Fire wood x2 + Activity bonus = 0.005 OXE',
    '7': 'Crystal ball - 3d printed figure x2, Audiobook x2, Fire wood x2 + Activity bonus = 0.0075 OXE',
    '8': 'None.',
  },
  "portalColor": {
    '1': 'Blue Shimmer - x0.0025 OXE ',
    '2': 'Pink Shimmer - x0.005 OXE ',
    '3': 'Orange Shimmer - x0.0075 OXE ',
    '4': 'Green Shimmer - x0.01 OXE ',
    '5': 'Gold Shimmer - x0.0125 OXE ',
    '6': 'Purple Shimmer - x0.015 OXE ',
  },
  "floatingItems": {
    '1': `Butterflies - pack of 5 in game assets like armour, weapons, magic.`,
    '2': `Treasure Chest - pack of 7 in game assets like armour, weapons, magic.`,
    '3': `Gems - pack of 9 in game assets like armour, weapons, magic.`,
    '4': `Swords - pack of 13 in game assets like armour, weapons, magic.`,
    '5': `Axes - pack of 15 in game assets like armour, weapons, magic.`,
    '6': `Shields - pack of 20 in game assets like armour, weapons, magic.`,
    '7': `Bow and Quiver - pack of 25 in game assets like armour, weapons, magic.`,
    '8': `Celtic Ankh - pack of 30 in game assets like armour, weapons, magic.`,
    '9': `none`,
  },
};

export const ShowAttributesModal: React.FC<ShowAttributesModalProps> = observer((props) => {
  const { tokenStore } = useStores();
  const [properties, setProperties] = React.useState<UnknownObject>({});

  useEffect(() => {
    setProperties(tokenStore.myTokens.find(token => token.id === props.tokenId)?.properties);
  }, [props.tokenId]);

  return (
    <div className={styles.container}>
      <div>
        <p className={styles.modalTitle}>Myth attributes</p>
        <CloseIcon className={styles.crossIcon} onClick={props.onClose}/>
      </div>
      <div className={styles.inputsContainer}>
        {Object.keys(properties).map((key) => (
          <p key={key}>{attributeMap[key] ? key.charAt(0).toUpperCase() + key.slice(1) + ": " + attributeMap[key][properties[key] as string] || `${key}: none`: `attribute unknown '${key}'`}</p>
        ))}
      </div>
      <div ></div>
    </div>
  );
});