import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import styles from './pages.module.css';
import { Button, ButtonType } from '../../../components/Button';
import { useStores } from '../../../stores/app';
import { Input } from '../../../components/Input';
import { pasCheck } from '../../../components/RegistrationLoginModal';
import { useSearchParams } from 'react-router-dom';
import { Modal } from '../../../components/Modal';
import { TwoFADisableEnableModal } from '../../../components/TwoFADisableEnableModal';
import { EmailVerifyTwoFAModal } from '../../../components/emailVerifyTwoFAModal';
import { PasswordChecker } from '../../../components/PasswordChecker';

export enum TwoFAType {
  email,
  authentificators,
}

export enum ResetPasswordState {
  none,
  begin,
  newPassword
}

export const Security = observer(() => {
  const { authStore, notificationStore } = useStores();
  const [searchParams] = useSearchParams();

  const [editPasswordState, setEditPasswordState] = React.useState<ResetPasswordState>(ResetPasswordState.none);
  const [newPassword, setNewPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');


  const [passwordStrength, setPasswordStrength] = React.useState<number>(0);

  const [oldPassword, setOldPassword] = React.useState<string>('');

  const [enableTwoFA, setEnableTwoFA] = React.useState<boolean>(!!authStore.user?.TwoFA || false);

  const [twoFAType, setTwoFAType] = React.useState<TwoFAType>(TwoFAType.authentificators);
  const [showTwoFAModal, setShowTwoFAModal] = React.useState<boolean>(false);
  const [showTwoFAEmailModal, setShowTwoFAEmailModal] = React.useState<boolean>(false);

  function saveChanges(): void {
    if (newPassword !== '') {
      if (!(newPassword.length > 3)) {
        notificationStore.enqueueSnackBar({ message: 'Password is to short', variant: 'error' });
        return;
      }
      if (!pasCheck.test(newPassword)) {
        notificationStore.enqueueSnackBar({ message: 'Password must have at least 1 letter and 1 number', variant: 'error' });
        return;
      }

    }
  }

  const submitPassword = () => {
    authStore.confirmPassword(oldPassword);
    // setEditPasswordState(ResetPasswordState.newPassword)
  }

  useEffect(() => {
    if (authStore.conformationPassword) {
      setEditPasswordState(ResetPasswordState.newPassword);
      authStore.resetConformationPassword();
    }
  }, [authStore.conformationPassword]);

  const savePassword = () => {
    if (newPassword !== confirmPassword) {
      notificationStore.enqueueSnackBar({
        variant: 'default',
        title: 'Passwords do not match',
        message: "",
      });
      return;
    }

    if (newPassword === oldPassword) {
      notificationStore.enqueueSnackBar({
        variant: 'default',
        title: 'New password must be different from the old one',
        message: "",
      });
      return;
    }

    if (passwordStrength != 3) {
      notificationStore.enqueueSnackBar({
        variant: 'default',
        title: 'Password is too weak',
        message: "",
      });
      return;
    }

    authStore.startResetPassword(newPassword);
    setEditPasswordState(ResetPasswordState.none);
  }

  return (
    <div>
      <div className={styles.headerContainer}>
        <p>Security</p>
        <Button text="Save changes" btnStyle={ButtonType.bright} style={{ fontWeight: 400 }} onClick={saveChanges}/>
      </div>
      <div>
        <div className={styles.profileSecurityContainer}>
          <div>
            <div>
              <div className={styles.optionHeader}>
                <h6>Password</h6>
                <p onClick={() => editPasswordState === ResetPasswordState.none? setEditPasswordState(ResetPasswordState.begin) : setEditPasswordState(ResetPasswordState.none)}>{editPasswordState === ResetPasswordState.none? "Edit" : "Close"}</p>
              </div>
              {searchParams.get("password") === "changed" ? <h4 className={styles.message}>Password was successfully changed</h4> : 
              authStore.isEmailSend ? <h4 className={styles.message}>A confirmation letter has been sent to your e-mail</h4> : 
              editPasswordState === ResetPasswordState.begin ?
              <div className={styles.currentPassword}>
                <Input value={oldPassword} type='password' placeHolder='Current password' onChange={(e) => setOldPassword(e.target.value)} inpSize={'small'}/>
                <Button text='Submit' btnStyle={ButtonType.bright} onClick={submitPassword}/>
              </div> :
              editPasswordState === ResetPasswordState.newPassword ?
              <div className={ styles.newPassword}>
                <Input value={newPassword} type='password' placeHolder='New password' onChange={(e) => setNewPassword(e.target.value)} inpSize={'small'}/> 
                <PasswordChecker password={newPassword} onPasswordStrengthChange={strength => setPasswordStrength(strength)}/>
                <Input value={confirmPassword} type='password' placeHolder='Confirm password' onChange={(e) => setConfirmPassword(e.target.value)} inpSize={'small'}/>
                <div>
                  <Button text='Save' disabled={passwordStrength !== 3} btnStyle={ButtonType.bright} onClick={savePassword}/>
                </div>
              </div>:
              <p>*******</p>}
            </div>
          </div>

          <div>
            <div>
              <div>
                <h6>Two-factor authentication</h6>
              </div>
              <div className={styles.twoFASection}>
                <div>
                  <h3>Enable 2FA</h3>
                  <p>You can protect your account from unauthorized access by requiring a second authentication method in addition to your password</p>
                </div>
                <Input type="switch" onChange={() => {
                  if (authStore.user?.TwoFA) setShowTwoFAModal(true);
                  setEnableTwoFA(!enableTwoFA)
                }} checked={!!authStore.user?.TwoFA}/>
              </div>
              {(enableTwoFA && !authStore.user?.TwoFA) ? <div className={styles.enableTwoFA}>
                    <div><Input type="radio" checked={twoFAType === TwoFAType.email} onChange={() => setTwoFAType(TwoFAType.email)}/><p>{`Send code to my email - ${authStore.user?.email}`}</p></div>
                    <div><Input type="radio" checked={twoFAType === TwoFAType.authentificators} onChange={() => setTwoFAType(TwoFAType.authentificators)}/><p>Use one of the authentificators</p></div>
                    <div>
                      <Button text = "Cnacel" btnStyle={ButtonType.outline} fullWidth onClick={() => setEnableTwoFA(false)}/>
                      <Button text = "Verify now" btnStyle={ButtonType.bright} fullWidth onClick={() => twoFAType === TwoFAType.authentificators ? setShowTwoFAModal(true) : setShowTwoFAEmailModal(true)}/>
                    </div>
                  </div> : <></>}
            </div>
          </div>

        </div>
      </div>
      <Modal isShow={showTwoFAModal}>
        <TwoFADisableEnableModal onClose={() => {setShowTwoFAModal(false); setEnableTwoFA(false)}} twoFAType={twoFAType} isTwoFAEnable={!!authStore.user?.TwoFA}/>
      </Modal>
      <Modal isShow={showTwoFAEmailModal}>
        <EmailVerifyTwoFAModal onClose={() => {setShowTwoFAEmailModal(false); setEnableTwoFA(false)}}/>
      </Modal>
    </div>
  );
});
