import { makeAutoObservable } from 'mobx';
import { BaseError, Routes } from '@medevelop-studio/mythopia-contract-lib';
import { Static } from '@sinclair/typebox';
import { AppStore } from '../app';
import axios, { AxiosResponse } from 'axios';
import { MINIO_URL, BASE_URL, readFileAsArrayBuffer } from '../../core';
import { errorToString } from '.';

export class AuthStore {
  public error: BaseError | null = null;

  public isSendRequest = false;
  public accessToken = localStorage.getItem('accessToken') || '';
  public user: Static<typeof Routes.mythopia.auth.login.responseType>["user"] | null = null;
  public visible = false;

  public isEmailSend = false;
  public isChangeEmailSend = false;

  public showModalLogin = false;
  public isLogin = true;

  public isShowForgotPassword = false;

  public base64TowFAQRCode = "";
  public towFACode = "";

  public accountCreatedSuccessfully = false;

  public conformationPassword = false;


  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  get isLoggedIn(): boolean {
    if (this.accessToken) {
      try {
        this.loginByToken(this.accessToken);
      } catch (err) {
        this.logout();
        return false;
      }

      return true;
    } else {
      return false;
    }
  }

  resetConformationPassword() {
    this.conformationPassword = false;
  }

  toggleShowPassword() {
    this.isShowForgotPassword = !this.isShowForgotPassword;
  }

  showLogin() {
    this.isLogin = true;
    this.showModalLogin = true;
  }

  showRegistration() {
    this.isLogin = false;
    this.showModalLogin = true;
  }

  showLastModal() {
    this.showModalLogin = true;
  }

  closeModal() {
    this.showModalLogin = false;
  }

  *updateUserInfo(data: { name?: string, phone?: string }) {
    this.isSendRequest = true;
    try {

      yield axios.post<{token: string}>(BASE_URL + "/auth/update", {
        accessToken: this.accessToken,
        ...data,
      });

      if (this.user) this.user = {...this.user, ...data, phoneNumber: data.phone || this.user.phoneNumber};

      this.rootStore.notificationStore.enqueueSnackBar({
        variant: 'default',
        title: `Your personal iformation has been updated`,
        message: "",
      });
    } catch (err) {
      this.isSendRequest = false;
      this.error = err as BaseError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: errorToString(err),
        variant: 'error',
      });
    }


    this.isSendRequest = false;
    this.rootStore.isLoading = false;
  }

  *setUserImage(file: File | null) {
    this.isSendRequest = true;
    try {

      if (!file) {
        yield axios.post<{token: string}>(BASE_URL + "/auth/update", {
          accessToken: this.accessToken,
          photo: '',
        });

        if (this.user) this.user.photo = '';
        this.rootStore.notificationStore.enqueueSnackBar({
          variant: 'default',
          title: 'Your photo has been deleted',
          message: "",
        });
      } else {
        const photoName = window.crypto.randomUUID() as string;
        const newPhotoUrl = `${MINIO_URL}/mythopia/${photoName}`;
        const arrayFile: string | ArrayBuffer | null = yield readFileAsArrayBuffer(file);
  
        yield axios.put(newPhotoUrl, arrayFile, {
            headers: {
              'Content-Type': file.type,
          },
        });
  
        yield axios.post<{token: string}>(BASE_URL + "/auth/update", {
          accessToken: this.accessToken,
          photo: newPhotoUrl,
        });
  
        if (this.user) this.user.photo = newPhotoUrl;

        this.rootStore.notificationStore.enqueueSnackBar({
          variant: 'default',
          title: 'Your photo has been updated',
          message: "",
        });
      }
    } catch (err) {
      this.isSendRequest = false;
      this.error = err as BaseError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: errorToString(err),
        variant: 'error',
      });
    }


    this.isSendRequest = false;
    this.rootStore.isLoading = false;
  }

  *confirmPassword(password: string) {
    this.isSendRequest = true;
    try {
      yield axios.post<{token: string}>(BASE_URL + "/auth/login", {
        email: this.user?.email,
        password,
        rememberMe: false,
      });

      this.conformationPassword = true;
    } catch (err) {
      this.conformationPassword = false;

      this.rootStore.notificationStore.enqueueSnackBar({
        variant: 'default',
        title: 'Wrong password',
        message: "",
      });
    }

    this.isSendRequest = false;
    this.rootStore.isLoading = false;
  }

  *loginByPassword(email: string, password: string, rememberMe: boolean) {
    this.isSendRequest = true;
    try {
      const resp: AxiosResponse<Static<typeof Routes.mythopia.auth.login.responseType>> = yield axios.post<{token: string}>(BASE_URL + "/auth/login", {
        email,
        password,
        rememberMe,
      });

      this.rootStore.isLoading = true;
      this.error = null;

      this.accessToken = resp.data.token;
      this.user = resp.data.user;

      localStorage.setItem('accessToken', resp.data.token);
    } catch (err) {
      this.isSendRequest = false;
      this.error = err as BaseError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: errorToString(err),
        variant: 'error',
      });
    }

    this.isSendRequest = false;
    this.rootStore.isLoading = false;
  }

  *loginByToken(accessToken: string) {
    this.isSendRequest = true;
    try {
      const resp: AxiosResponse<Static<typeof Routes.mythopia.auth.loginByToken.responseType>> = yield axios.post<{token: string}>(BASE_URL + "/auth/loginByToken", {
        token: accessToken,
      });

      this.rootStore.isLoading = true;
      this.error = null;

      this.accessToken = resp.data.token;
      this.user = resp.data.user;

      localStorage.setItem('accessToken', resp.data.token);
    } catch (err) {
      this.isSendRequest = false;
      this.error = err as BaseError;

      this.logout();
    }

    this.isSendRequest = false;
    this.rootStore.isLoading = false;
  }

  *loginByGoogle(code: string) {
    this.isSendRequest = true;
    try {
      const resp: AxiosResponse<Static<typeof Routes.mythopia.auth.loginByGoogle.responseType>> = yield axios.post<{token: string}>(BASE_URL + "/auth/google", {
        code,
      });

      this.rootStore.isLoading = true;
      this.error = null;

      this.accessToken = resp.data.token;
      this.user = resp.data.user;

      localStorage.setItem('accessToken', resp.data.token);
    } catch (err) {
      this.isSendRequest = false;
      this.error = err as BaseError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: errorToString(err),
        variant: 'error',
      });
    }

    this.isSendRequest = false;
    this.rootStore.isLoading = false;
  }

  *createAccount(email: string, password: string, name: string) {
    this.isSendRequest = true;
    try {
      yield axios.post<{token: string}>(BASE_URL + "/auth/registration", {
        email,
        password,
        name,
      });
    
      this.accountCreatedSuccessfully = true;
      this.rootStore.isLoading = true;
      this.error = null;
    } catch (err) {
      this.isSendRequest = false;
      this.error = err as BaseError;

      this.logout();
    }

    this.isSendRequest = false;
    this.rootStore.isLoading = false;
  }

  *startResetPassword(newPass: string, email?: string) {
    this.isSendRequest = true;
    try {
      yield axios.get<{token: string}>(BASE_URL + "/auth/startResetPassword", {
        params: {
          email: email || this.user?.email || '',
          newPass,
        }
      });

      this.rootStore.isLoading = true;
      this.error = null;
      this.isEmailSend = true;

      this.rootStore.notificationStore.enqueueSnackBar({
        variant: 'default',
        title: 'Email confirmation is required',
        message: "We send you an email with the appropriate link. If you change your mind about changing your password, you can simply not follow the link in the email.",
      });
    } catch (err) {
      this.isSendRequest = false;
      this.error = err as BaseError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: errorToString(err),
        variant: 'error',
      });
    }

    this.isSendRequest = false;
    this.rootStore.isLoading = false;
  }

  *startResetEmail(newEmail: string) {
    this.isSendRequest = true;
    try {
      yield axios.get<{token: string}>(BASE_URL + "/auth/startChangeEmail", {
        params: {
          email: this.user?.email || '',
          newEmail,
        }
      });

      this.rootStore.isLoading = true;
      this.error = null;
      this.isChangeEmailSend = true;

      this.rootStore.notificationStore.enqueueSnackBar({
        variant: 'default',
        title: 'We send you an email with the appropriate link',
        message: "",
      });
    } catch (err) {
      this.isSendRequest = false;
      this.error = err as BaseError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: errorToString(err),
        variant: 'error',
      });
    }

    this.isSendRequest = false;
    this.rootStore.isLoading = false;
  }

  *getTwoFAQRCode() {
    this.isSendRequest = true;
    try {
      const resp: AxiosResponse<Static<typeof Routes.mythopia.auth.getQRCodeTwoFA.responseType>> = yield axios.get<{token: string}>(BASE_URL + "/2fa/qrCode", {
        params: {
          accessToken: this.accessToken,
        }
      });

      this.base64TowFAQRCode = resp.data.qrCode;
      this.towFACode = resp.data.code;

      this.rootStore.isLoading = true;
      this.error = null;
    } catch (err) {
      this.isSendRequest = false;
      this.error = err as BaseError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: errorToString(err),
        variant: 'error',
      });
    }

    this.isSendRequest = false;
    this.rootStore.isLoading = false;
  }


  *verifyTwoFA(code: string) {
    this.isSendRequest = true;
    try {
      const resp: AxiosResponse<Static<typeof Routes.mythopia.auth.verifyCodeTwoFA.responseType>> = yield axios.get<{token: string}>(BASE_URL + "/2fa/verify", {
        params: {
          accessToken: this.accessToken,
          code,
        }
      });

      if (this.user) this.user.TwoFA = "authenticator";
      if (!resp.data.verify) {
        this.rootStore.notificationStore.enqueueSnackBar({
          message: "You enter wrong code, please try again",
          variant: 'error',
        });
      }

      this.rootStore.isLoading = true;
      this.error = null;
    } catch (err) {
      this.isSendRequest = false;
      this.error = err as BaseError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: errorToString(err),
        variant: 'error',
      });
    }

    this.isSendRequest = false;
    this.rootStore.isLoading = false;
  }

  *disableTwoFA(code: string) {
    this.isSendRequest = true;
    try {
      yield axios.get<{token: string}>(BASE_URL + "/2fa/disable", {
        params: {
          accessToken: this.accessToken,
          code,
        }
      });

      if (this.user) this.user.TwoFA = undefined;
      // if (!resp.data.verify) {
      //   this.rootStore.notificationStore.enqueueSnackBar({
      //     message: "You enter wrong code, please try again",
      //     variant: 'error',
      //   });
      // }

      this.rootStore.isLoading = true;
      this.error = null;
    } catch (err) {
      this.isSendRequest = false;
      this.error = err as BaseError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: errorToString(err),
        variant: 'error',
      });
    }

    this.isSendRequest = false;
    this.rootStore.isLoading = false;
  }

  *sendTwoFAOnEmail() {
    this.isSendRequest = true;
    try {
      yield axios.get<{token: string}>(BASE_URL + "/2fa/sendEmailTwoFA", {
        params: {
          accessToken: this.accessToken,
        }
      });

      this.rootStore.isLoading = true;
      this.error = null;
    } catch (err) {
      this.isSendRequest = false;
      this.error = err as BaseError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: errorToString(err),
        variant: 'error',
      });
    }

    this.isSendRequest = false;
    this.rootStore.isLoading = false;
  }

  *verifyTwoFAOnEmail(code: string) {
    this.isSendRequest = true;
    try {
      yield axios.get<{token: string}>(BASE_URL + "/2fa/verifyEmailTwoFA", {
        params: {
          accessToken: this.accessToken,
          code,
        }
      });

      if (this.user) this.user.TwoFA = "mail";

      this.rootStore.isLoading = true;
      this.error = null;
    } catch (err) {
      this.isSendRequest = false;
      this.error = err as BaseError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: errorToString(err),
        variant: 'error',
      });
    }

    this.isSendRequest = false;
    this.rootStore.isLoading = false;
  }

  logout() {
    this.accessToken = '';
    this.user = null;
    localStorage.removeItem('accessToken');
  }
}
