import React from 'react';
import styles from './IconBtn.module.css';
import { ButtonType } from '../Button';

interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isActive?: boolean;
  fillPrimary?: boolean;
  icon?: JSX.Element;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  btnStyle?: ButtonType;
}

const sizeMap = {
  "small": styles.defaultSmall,
  "medium": styles.defaultMid,
  "large": styles.defaultLarge,
};

const typeMap = {
  [ButtonType.default]: "",
  [ButtonType.outline]: styles.outlineBtn,
  [ButtonType.bright]: "",
};

export const IconButton: React.FC<IconButtonProps> = (props) => {
  return (
    <button className={`
      ${typeMap[props.btnStyle || ButtonType.default]}
      ${styles.defaultBtn}
      ${props.fillPrimary ? styles.btnFill : ''}
      ${sizeMap[props.size || 'large']}
      ${props.isActive ? styles.defaultBtnActive : ''}
      ${props.disabled ? styles.defaultBtnDisabled : ''}
    `} {...props}>
      {props.icon}
      </button>
  );
};