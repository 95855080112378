import * as React from "react";
const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.3333 11.3334C29.3333 16.1201 25.4533 20.0001 20.6667 20.0001C20.44 20.0001 20.2 19.9868 19.9733 19.9734C19.64 15.7468 16.2533 12.3601 12.0267 12.0267C12.0133 11.8001 12 11.5601 12 11.3334C12 6.54675 15.88 2.66675 20.6667 2.66675C25.4533 2.66675 29.3333 6.54675 29.3333 11.3334Z"
      stroke="#FFDF76"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 20.6667C20 25.4533 16.12 29.3333 11.3333 29.3333C6.54667 29.3333 2.66667 25.4533 2.66667 20.6667C2.66667 15.88 6.54667 12 11.3333 12C11.56 12 11.8 12.0133 12.0267 12.0267C16.2533 12.36 19.64 15.7467 19.9734 19.9733C19.9867 20.2 20 20.44 20 20.6667Z"
      stroke="#FFDF76"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.16 19.4934L11.3333 17.3334L12.5067 19.4934L14.6667 20.6667L12.5067 21.84L11.3333 24L10.16 21.84L8 20.6667L10.16 19.4934Z"
      stroke="#FFDF76"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SVGComponent;