import * as React from "react";
const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.3533 7.04245L16 14.3971L8.64667 7.04245C8.20401 6.59978 7.48667 6.59978 7.04401 7.04245C6.60134 7.48512 6.60134 8.20245 7.04401 8.64512L14.3973 15.9998L7.04401 23.3531C6.60134 23.7958 6.60134 24.5131 7.04401 24.9558C7.48667 25.3984 8.20401 25.3984 8.64667 24.9558L16 17.6024L23.3533 24.9558C23.796 25.3984 24.5133 25.3984 24.956 24.9558C25.3987 24.5131 25.3987 23.7958 24.956 23.3531L17.6027 15.9998L24.956 8.64645C25.3987 8.20378 25.3987 7.48645 24.956 7.04378C24.5147 6.60112 23.796 6.60112 23.3533 7.04245Z"
      fill="#21D9F7"
    />
  </svg>
);
export default SVGComponent;
