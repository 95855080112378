import * as React from "react";
const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={119}
    height={120}
    viewBox="0 0 119 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_135_231)">
      <path
        d="M104.851 60C104.851 57.5259 104.646 55.0981 104.262 52.7233L118.406 50.2293L118.174 48.9261L104.024 51.4201C103.092 46.5447 101.391 41.9471 99.0364 37.7597L111.486 30.5689L110.825 29.4245L98.3749 36.6086C95.8611 32.4146 92.6924 28.6637 89.0143 25.4818L98.269 14.4542L97.2569 13.6009L88.0088 24.6218C84.258 21.5656 80.0176 19.0915 75.4201 17.3385L80.3352 3.83683L79.0915 3.38699L74.1764 16.8886C69.6781 15.3208 64.8622 14.441 59.8545 14.3682V0H58.5314V14.3749C53.5237 14.4476 48.7078 15.3275 44.2095 16.8953L39.2944 3.39361L38.0507 3.84344L42.9658 17.3451C38.3682 19.0981 34.1279 21.5722 30.3771 24.6284L21.129 13.6075L20.1169 14.4609L29.3716 25.4884C25.6935 28.6703 22.5248 32.4212 20.011 36.6152L7.56119 29.4245L6.89967 30.5689L19.3495 37.7597C17.0011 41.9537 15.2944 46.5513 14.3616 51.4201L0.231533 48.9261L0 50.2293L14.1433 52.7233C13.7596 55.0915 13.5546 57.5193 13.5546 60C13.5546 62.4807 13.7596 64.9019 14.1433 67.2701L0 69.7641L0.231533 71.0673L14.3815 68.5733C15.3142 73.4487 17.0143 78.0463 19.3694 82.2337L6.91952 89.4245L7.58104 90.5689L20.0309 83.3782C22.5447 87.5722 25.7133 91.3231 29.3914 94.505L20.1367 105.533L21.1488 106.386L30.3969 95.3649C34.1477 98.4212 38.3881 100.895 42.9857 102.648L38.0706 116.15L39.3142 116.6L44.2293 103.098C48.7277 104.666 53.5436 105.552 58.5513 105.619V119.987H59.8743V105.619C64.882 105.546 69.6979 104.666 74.1963 103.098L79.1114 116.6L80.355 116.15L75.4399 102.648C80.0375 100.895 84.2779 98.4212 88.0287 95.3649L97.2767 106.386L98.2889 105.533L89.0408 94.505C92.7189 91.3231 95.8875 87.5722 98.4013 83.3782L110.851 90.5689L111.513 89.4245L99.0629 82.2337C101.411 78.0397 103.118 73.4421 104.051 68.5733L118.194 71.0673L118.426 69.7641L104.282 67.2701C104.666 64.9019 104.871 62.4741 104.871 60H104.851ZM59.2062 104.322C34.7696 104.322 14.8842 84.4366 14.8842 60C14.8842 35.5634 34.763 15.6781 59.2062 15.6781C83.6494 15.6781 103.528 35.5634 103.528 60C103.528 84.4366 83.6428 104.322 59.2062 104.322Z"
        fill="#FFDF76"
      />
      <path
        d="M96.3308 51.731H97.6869C94.452 36.6483 82.5513 24.7541 67.4752 21.5193V22.8754C81.8236 26.0706 93.1356 37.3826 96.3308 51.731Z"
        fill="#FFDF76"
      />
      <path
        d="M50.9371 22.8754V21.5193C35.8545 24.7541 23.9603 36.6549 20.7255 51.731H22.0816C25.2767 37.3826 36.5887 26.0706 50.9371 22.8754Z"
        fill="#FFDF76"
      />
      <path
        d="M22.075 68.269H20.7188C23.9537 83.3517 35.8545 95.2459 50.9305 98.4807V97.1246C36.5821 93.9295 25.2701 82.6174 22.075 68.269Z"
        fill="#FFDF76"
      />
      <path
        d="M67.4752 97.1246V98.4807C82.5579 95.2459 94.452 83.3451 97.6869 68.269H96.3308C93.1356 82.6174 81.8236 93.9295 67.4752 97.1246Z"
        fill="#FFDF76"
      />
      <path
        d="M59.2062 16.4917L15.6913 60L59.1996 103.508L102.708 60L59.2062 16.4917ZM39.3407 79.8588V40.1411H79.0584V79.8588H39.3407ZM59.8677 38.8181V19.0253L79.6604 38.8181H59.8677ZM58.5446 38.8181H38.7519L58.5446 19.0253V38.8181ZM38.0176 59.3384H18.2249L38.0176 39.5457V59.3384ZM38.0176 60.6615V80.4542L18.2249 60.6615H38.0176ZM58.5446 81.1819V100.975L38.7519 81.1819H58.5446ZM59.8677 81.1819H79.6604L59.8677 100.975V81.1819ZM80.3881 60.6615H100.181L80.3881 80.4542V60.6615ZM80.3881 59.3384V39.5457L100.181 59.3384H80.3881Z"
        fill="#FFDF76"
      />
    </g>
    <defs>
      <clipPath id="clip0_135_231">
        <rect width={118.406} height={120} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SVGComponent;
