import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import styles from './PasswordChecker.module.css';

interface PasswordCheckerProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  password: string;
  onPasswordStrengthChange?: (strength: number) => void;
}

export const pasCheck = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\s]+$/

const strengthColors = [
  'rgba(33, 217, 247, .3)',
  '#C03838',
  '#FFDF76',
  '#21D9F7',
];

export const PasswordChecker: React.FC<PasswordCheckerProps> = observer((props) => {
  const [passwordStrength, setPasswordStrength] = React.useState(0);

  useEffect(() => {
    let currPasswordStrength = 0;

    if (props.password.length > 3) currPasswordStrength++;
    if (props.password.length >= 8) currPasswordStrength++;
    if (pasCheck.test(props.password)) currPasswordStrength++;

    setPasswordStrength(currPasswordStrength);
    if (props.onPasswordStrengthChange) props.onPasswordStrengthChange(currPasswordStrength);
  }, [props.password]);


  return (
    <div className={styles.passwordStrength}>
      <div className={styles.passwordStrengthBar}>
        <p>Password strength: </p>
        <div style={{ backgroundColor: passwordStrength >= 1 ? strengthColors[passwordStrength] : strengthColors[0] }}/>
        <div style={{ backgroundColor: passwordStrength >= 2 ? strengthColors[passwordStrength] : strengthColors[0] }}/>
        <div style={{ backgroundColor: passwordStrength >= 3 ? strengthColors[passwordStrength] : strengthColors[0] }}/>
      </div>
      <div className={styles.passwordStates}>
        <div style={{ backgroundColor: props.password.length >= 8 ? strengthColors[3] : strengthColors[0] }}/>
        <p>Use at least 8 symbols</p>
      </div>
      <div className={styles.passwordStates}>
        <div style={{ backgroundColor: pasCheck.test(props.password) ? strengthColors[3] : strengthColors[0] }}/>
        <p>Use at least 1 letter and 1 number</p>
      </div>
    </div>
  );
});