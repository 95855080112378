import { makeAutoObservable } from 'mobx';
import { BaseError, Routes } from '@medevelop-studio/mythopia-contract-lib';
import { Static } from '@sinclair/typebox';
import { AppStore } from '../app';
import axios, { AxiosResponse } from 'axios';
import { BASE_URL } from '../../core';
import { errorToString } from '../auth';

export class TokenStore {
  public error: BaseError | null = null;

  public isSendRequest = false;
  public walletAdressToPay = "";
  public myTokens: Static<typeof Routes.mythopia.token.getMyTokens.responseType>['tokens'] = [];
  public stakingInfo: Static<typeof Routes.mythopia.token.getStakingInfo.responseType>['stakingInfo'] = [];

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *giveToken(tokenId: number, recieverEmail: string) {
    this.isSendRequest = true;
    try {
      yield axios.post<{token: string}>(BASE_URL + "/token/giveToken", {
        accessToken: this.rootStore.authStore.accessToken,
        tokenId,
        recieverEmail,
      });

      this.rootStore.notificationStore.enqueueSnackBar({
        title: "Success",
        message: "Token was successfully given",
        variant: 'default',
      });

      this.rootStore.isLoading = true;
      this.error = null;
    } catch (err) { 
      this.isSendRequest = false;
      this.error = err as BaseError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: errorToString(err),
        variant: 'default',
      });
    }

    this.isSendRequest = false;
    this.rootStore.isLoading = false;
  }

  *getMyTokens() {
    this.isSendRequest = true;
    try {
      const resp: AxiosResponse<Static<typeof Routes.mythopia.token.getMyTokens.responseType>> = yield axios.get<{token: string}>(BASE_URL + "/token/my", {
        params: {
          accessToken: this.rootStore.authStore.accessToken,
        }
      });

      this.myTokens = resp.data.tokens;

      this.rootStore.isLoading = true;
      this.error = null;
    } catch (err) {
      this.isSendRequest = false;
      this.error = err as BaseError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: errorToString(err),
        variant: 'error',
      });
    }

    this.isSendRequest = false;
    this.rootStore.isLoading = false;
  }

  *getMyStakingInfo() {
    this.isSendRequest = true;
    try {
      const resp: AxiosResponse<Static<typeof Routes.mythopia.token.getStakingInfo.responseType>> = yield axios.get<{token: string}>(BASE_URL + "/token/staking", {
        params: {
          accessToken: this.rootStore.authStore.accessToken,
        }
      });

      this.stakingInfo = resp.data.stakingInfo;

      this.rootStore.isLoading = true;
      this.error = null;
    } catch (err) {
      this.isSendRequest = false;
      this.error = err as BaseError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: errorToString(err),
        variant: 'error',
      });
    }

    this.isSendRequest = false;
    this.rootStore.isLoading = false;
  }
}
