import React from 'react';
import { CustomContentProps, SnackbarContent } from 'notistack';
import styles from './SnackbarCustom.module.css';
import TickIcon from '../../app/icons/TickIcon';
import InfoPopupIcon from '../../app/icons/InfoPopupIcon';

export enum IconType {
  check = 'check',
  info = 'info',
}

const typeToIcon: {[key in IconType]: JSX.Element} = {
  'check': <TickIcon/>,
  'info': <InfoPopupIcon/>,
}

interface SnackbarCustomProps extends CustomContentProps {
  title?: string;
  iconType?: IconType,
}

const SnackbarCustom = React.forwardRef<HTMLDivElement, SnackbarCustomProps>((props, ref) => {
  const {
    message,
    title,
    iconType
  } = props;

  return (
    <SnackbarContent ref={ref} role="alert" className={styles.container}>
      <div>
        {typeToIcon[iconType || 'info']}
        <h1>{title || "Error"}</h1>
      </div>
      <p>{message}</p>
    </SnackbarContent>
  );
});

SnackbarCustom.displayName = 'SnackbarCustom';

export { SnackbarCustom };