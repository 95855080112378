import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import styles from './emailVerifyTwoFAModal.module.css';
import CloseIcon from '../../app/icons/CloseIcon';
import { Input } from '../Input';
import { useStores } from '../../stores/app';
import { Button, ButtonType } from '../Button';

interface EmailVerifyTwoFAModalProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClose?: () => void;
}

export const EmailVerifyTwoFAModal: React.FC<EmailVerifyTwoFAModalProps> = observer((props) => {
  const { authStore } = useStores();
  const [code, setCode] = React.useState<string>('');

  const submitCode = () => {
    authStore.verifyTwoFAOnEmail(code);
    if (props.onClose) props.onClose();
  }

  useEffect(() => {
    authStore.sendTwoFAOnEmail();
  }, []);

  return (
    <div className={styles.container}>
      <div>
        <h2 className={styles.modalTitle}>Enable Email 2FA</h2>
        <CloseIcon className={styles.crossIcon} onClick={props.onClose}/>
      </div>
      <div className={styles.content}>
        <h1>We have sent you a code to the email address you used for registration. This code will activate 2FA</h1>
      </div>
      <form onSubmit={(e) => { e.preventDefault(); submitCode()}} className={styles.inputContainer}>
        <Input placeHolder="Enter code" value={code} onChange={(e) => setCode(e.target.value)}/>
        <div className={styles.btnContainer}>
          <Button text='Cancel' fullWidth btnStyle={ButtonType.outline} onClick={props.onClose}/>
          <Button text='Verify' fullWidth btnStyle={ButtonType.bright} onClick={() => submitCode()}/>
        </div>
      </form>
    </div>
  );
});