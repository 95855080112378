import React from "react";

function TwitterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g clipPath="url(#clip0_312_1038)">
        <path
          fill="#0A0E0D"
          d="M18.979 13.55L30.636 0h-2.763L17.752 11.765 9.668 0H.344L12.57 17.79.344 32h2.762l10.689-12.424L22.332 32h9.324L18.978 13.55zm-3.784 4.398l-1.238-1.772L4.102 2.08h4.243l7.953 11.377 1.238 1.771 10.338 14.788h-4.242l-8.437-12.067z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_312_1038">
          <path fill="#fff" d="M0 0H32V32H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default TwitterIcon;
