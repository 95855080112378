import * as React from "react";
const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={24} height={24} rx={12} fill="#C03838" fillOpacity={0.15} />
    <path
      d="M11.9998 9.15996V12.36M11.9998 19.1998C8.02335 19.1998 4.7998 15.9763 4.7998 11.9998C4.7998 8.02335 8.02335 4.7998 11.9998 4.7998C15.9763 4.7998 19.1998 8.02335 19.1998 11.9998C19.1998 15.9763 15.9763 19.1998 11.9998 19.1998ZM12.0396 14.76V14.84L11.96 14.8396V14.76H12.0396Z"
      stroke="#C03838"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SVGComponent;
