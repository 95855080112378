import React from 'react';
import { observer } from 'mobx-react';
import styles from './faq.module.css';
import { Accordion } from '../Accordion';
import OBJECTS from '../../app/icons/objects/OBJECTS';
import ArrowDownIcon from '../../app/icons/ArrowDownIcon';
import OBJECTS4 from '../../app/icons/objects/OBJECTS4';
import OBJECTS5 from '../../app/icons/objects/OBJECTS5';
import OBJECTS1 from '../../app/icons/objects/OBJECTS1';

export const FaqSection = observer(() => {
  
  return (
    <div className={styles.faqContainer}>
    <h1>FAQ</h1>
    <Accordion titleComponent={
      <div className={styles.fqaHeader}>
        <div>
          <OBJECTS/>
          What is Mythtopia?
        </div>
        <ArrowDownIcon/>
        <div/>
      </div>
    }>
      <h1 className={styles.accordionContent}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non diam auctor
      </h1>
    </Accordion>
    <Accordion titleComponent={
      <div className={styles.fqaHeader}>
        <div>
          <OBJECTS4/>
          How to buy Myths?
        </div>
        <ArrowDownIcon/>
        <div/>
      </div>
    }>
      <h1 className={styles.accordionContent}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non diam auctor
      </h1>
    </Accordion>
    <Accordion titleComponent={
      <div className={styles.fqaHeader}>
        <div>
          <OBJECTS5/>
          How to join to Legend?
        </div>
        <ArrowDownIcon/>
        <div/>
      </div>
    }>
      <h1 className={styles.accordionContent}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non diam auctor
      </h1>
    </Accordion>
    <Accordion titleComponent={
      <div className={styles.fqaHeader}>
        <div>
          <OBJECTS1/>
          What is eco-glamping?
        </div>
        <ArrowDownIcon/>
        <div/>
      </div>
    }>
      <h1 className={styles.accordionContent}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non diam auctor
      </h1>
    </Accordion>
  </div>
  );
});