import React from "react";

function BellNotificationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 17v1a3 3 0 11-6 0v-1m6 0H9m6 0h4a1 1 0 001-1v-.586a1 1 0 00-.293-.707l-.51-.51a.67.67 0 01-.197-.475V10c0-.176-.006-.351-.02-.524M9 17H5a1 1 0 01-1-1v-.586a1 1 0 01.293-.707l.51-.511A.669.669 0 005 13.722V10a7 7 0 019.045-6.696m4.935 6.172a4 4 0 10-4.935-6.173m4.935 6.173a4 4 0 01-4.935-6.173m4.935 6.173s0 0 0 0zm-4.935-6.172h.002"
      ></path>
    </svg>
  );
}

export default BellNotificationIcon;
