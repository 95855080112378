import * as React from "react";
const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.7589 5.7146L19.7589 33.9989"
      stroke="#21D9F7"
      strokeWidth={4}
      strokeLinecap="round"
    />
    <path
      d="M19.889 34.1428L5.24183 19.4956"
      stroke="#21D9F7"
      strokeWidth={4}
      strokeLinecap="round"
    />
    <path
      d="M20.3941 33.6377L35.0413 18.9905"
      stroke="#21D9F7"
      strokeWidth={4}
      strokeLinecap="round"
    />
  </svg>
);
export default SVGComponent;