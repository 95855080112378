import React, { useEffect } from 'react';
import { useStores } from '../../stores/app';
import { observer } from 'mobx-react';
import styles from './StakingList.module.css';
import InfoIcon from '../../app/icons/InfoIcon';
import { Button, ButtonType } from '../Button';
import { IconButton } from '../IconBtn';
import MoreIcon from '../../app/icons/MoreIcon';

// interface StakingListProps {
// }

const getDatyMonthStirng = (date: Date) => String(date.getDate()).padStart(2, '0') + "." + String(date.getMonth()).padStart(2, '0')

export const StakingList = observer(() => {
  const { tokenStore } = useStores();

  useEffect(() => {
    if (tokenStore.stakingInfo.length === 0) tokenStore.getMyStakingInfo();
  }, [])
  
  return (
    <div className={styles.listContainer}>
      {tokenStore.stakingInfo?.map((item) => (
        <div key={item.tokenId} style={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <img src={item.photo} style={{ width: '60px', borderRadius: '10px' }}></img>
          <p style={{ fontSize: '20px', fontWeight: '600' }}>{`Myth #${item.tokenId.toString().padStart(5, '0')}`}</p>
          <div>
            <p style={{ fontSize: '15px', fontWeight: '400', margin: 0 }}>{item.houseName} - £{item.price}</p>
            <p style={{ fontSize: '15px', fontWeight: '400', margin: 0 }}>{getDatyMonthStirng(new Date(item.stakingAt)) + ", " + getDatyMonthStirng(new Date(item.stakingTo))}</p>
          </div>
          <p style={{ fontSize: '15px', fontWeight: '400', color: '#FFDF76' }}>{item.status}</p>
          <div style={{  }}>
            <p style={{ fontSize: '20px', fontWeight: '500', margin: 0, textAlign: 'end' }}>£{item.price}</p>
            <p style={{ fontSize: '15px', fontWeight: '400', margin: 0, display: 'flex', alignItems: 'center' }}><InfoIcon/>{"10% from £" + item.price}</p>
          </div>
          <div style={{ display: 'flex',  }}>
            <Button text='Withdraw' btnStyle={ButtonType.bright} style={{ marginRight: '10px'}}></Button>
            <IconButton size='medium' btnStyle={ButtonType.outline} icon={<MoreIcon/>}></IconButton>
          </div>
        </div>
      ))}
    </div>
  );
});