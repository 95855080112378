import * as React from "react";
const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.727 17.4313C11.326 17.8523 10.651 17.8433 10.261 17.4123L6.86899 13.6573C6.55499 13.3083 6.58199 12.7713 6.92999 12.4563C7.27999 12.1413 7.81699 12.1713 8.13099 12.5173L11.017 15.7123L16.885 9.50131C17.208 9.16031 17.746 9.14731 18.086 9.47231C18.426 9.79631 18.44 10.3343 18.115 10.6743L11.727 17.4313Z"
      fill="black"
    />
  </svg>
);
export default SVGComponent;
