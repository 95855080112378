import React from "react";

function LockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#21D9F7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 9V6a3 3 0 00-3-3h-2a3 3 0 00-3 3v3m8 0H8m8 0a3 3 0 013 3v6a3 3 0 01-3 3H8a3 3 0 01-3-3v-6a3 3 0 013-3m4 5v3m1-3a1 1 0 11-2 0 1 1 0 012 0z"
      ></path>
    </svg>
  );
}

export default LockIcon;
