import React, { ReactNode } from 'react';
import styles from './Button.module.css';

export enum ButtonType {
  default,
  outline,
  bright,
}

const buttonTypeStyles = {
  [ButtonType.default]: "",
  [ButtonType.outline]: styles.outlineBtn,
  [ButtonType.bright]: styles.brightBtn,
};

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isActive?: boolean;
  text?: string;
  fullWidth?: boolean;
  icon?: JSX.Element;
  disabled?: boolean;
  btnStyle?: ButtonType;
  children?: ReactNode;
}

export const Button: React.FC<ButtonProps> = (props) => {
  return (
    <button className={`
      ${props.className || ''}
      ${buttonTypeStyles[props.btnStyle || ButtonType.default]}
      ${styles.defaultBtn}
      ${props.fullWidth ? styles.fullWidth : ''}
      ${props.isActive ? styles.defaultBtnActive : ''}
      ${props.disabled ? styles.defaultBtnDisabled : ''}
    `} {...props}>
      {props.icon}
      <p>{props.text}</p>
      {props.children}
      </button>
  );
};