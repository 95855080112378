import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import styles from './TwoFADisableEnableModal.module.css';
import CloseIcon from '../../app/icons/CloseIcon';
import { TwoFAType } from '../../pages/profile/pages';
import { Input } from '../Input';
import CopyIcon from '../../app/icons/CopyIcon';
import { useStores } from '../../stores/app';
import { Button, ButtonType } from '../Button';

interface TwoFADisableEnableModalProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClose?: () => void;
  twoFAType: TwoFAType;
  isTwoFAEnable: boolean;
}

export const TwoFADisableEnableModal: React.FC<TwoFADisableEnableModalProps> = observer((props) => {
  const { authStore } = useStores();
  const [code, setCode] = React.useState<string>('');

  const submitCode = () => {
    if (props.isTwoFAEnable) authStore.disableTwoFA(code);
    else authStore.verifyTwoFA(code);
    
    if (props.onClose) props.onClose();
  }

  useEffect(() => {
    if (props.isTwoFAEnable) {
      if (authStore.user?.TwoFA === 'mail') {
        authStore.sendTwoFAOnEmail();
      }
    } else {
      authStore.getTwoFAQRCode();
    }
  }, []);

  return (
    <div className={styles.container}>
      <div>
        <h2 className={styles.modalTitle}>{props.isTwoFAEnable ? "Cancelling 2FA" : "Enable 2FA"}</h2>
        <CloseIcon className={styles.crossIcon} onClick={props.onClose}/>
      </div>
      { props.isTwoFAEnable ? 
      <>
        <div className={styles.content}>
          {authStore.user?.TwoFA === 'mail' ? 
          <h1 className={styles.description}>If you are certain about disabling two-factor authentication, please be aware that it will impact the security of your account. Please enter the code we sent you on email</h1> :
          <h1 className={styles.description}>If you are certain about disabling two-factor authentication, please be aware that it will impact the security of your account. Please enter the code generated by your authenticator below</h1>}
          
        </div>
      </> : 
      <>
        <div className={styles.content}>
          <h1>1. You will need an authenticator to complete this process</h1>
          <p className={styles.description}>Such as one of the following: Google Authenticator, Microsoft Authenticator or other</p>
        </div>
        <div className={styles.content}>
          <h1>2. Scan the QR-code with your authenticator</h1>
          <div className={styles.qrCodeContainer}>
            <img src={authStore.base64TowFAQRCode || "https://placehold.co/130x130"}/>
            <div>
              <p className={styles.description}>If you can’t scan the code, you can enter this key into your authentication app</p>
              <div className={styles.walletInput}>
                <p className={styles.description}>{authStore.towFACode || "loading..."}</p>
                <CopyIcon onClick={() => navigator.clipboard.writeText(authStore.towFACode || "")} style={{ cursor: 'pointer'}} width={32} height={32}/>
                {/* <IconButton fillPrimary size='medium' icon={} /> */}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <h1>3. After scanning the QR-code above, enter the 6-digit code generated by your authenticator below</h1>
        </div>
      </>}
      
      <form onSubmit={(e) => { e.preventDefault(); submitCode()}} className={styles.inputContainer}>
        <Input placeHolder="Enter code" value={code} onChange={(e) => setCode(e.target.value)}/>
        <div className={styles.btnContainer}>
          <Button text='Cancel' fullWidth btnStyle={ButtonType.outline} onClick={props.onClose}/>
          <Button text='Verify' fullWidth btnStyle={ButtonType.bright} onClick={() => submitCode()} style={{ width: "500px" }}/>
        </div>
      </form>
    </div>
  );
});