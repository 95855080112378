import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { ProfilePicture } from '../../components/profilePicture';
import { useStores } from '../../stores/app';
import styles from './profile.module.css';
import { MenuButton } from '../../components/MenuButton';
import HistoryIcon from '../../app/icons/HistoryIcon';
import BookmarkIcon from '../../app/icons/BookmarkIcon';
import PieChartIcon from '../../app/icons/PieChartIcon';
import LockIcon from '../../app/icons/LockIcon';
import BellNotificationIcon from '../../app/icons/BellNotificationIcon';
import CommentIcon from '../../app/icons/CommentIcon';
import ProfileDeleteIcon from '../../app/icons/ProfileDeleteIcon';
import { Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { HistoryPage, PersonalInformation, MyMyths, Security } from './pages';
import LogoutIcon from '../../app/icons/LogoutIcon';
import { Modal } from '../../components/Modal';
import { LogOutConfirmModal } from '../../components/LogOutConfirmModal';

const tabIndexToPage = [
  <HistoryPage key={0}/>,
  <MyMyths key={1}/>,
  <PersonalInformation key={2}/>,
  <Security key={3}/>
];

export const Profile = observer(() => {
  const { authStore } = useStores();
  const [searchParams] = useSearchParams();
  const [currPage, setCurrPage] = React.useState<number>(0);

  const [logOutConfirm, setLogOutConfirm] = React.useState<boolean>(false);

  useEffect(() => {
    if (searchParams.get("page") === "myMyths") setCurrPage(1);
    if (searchParams.get("page") === "personalInfo") setCurrPage(2);
    if (searchParams.get("page") === "security") setCurrPage(3);
  }, [])

  if (!authStore.isLoggedIn) {
    return <Navigate to="/getMyth" />;
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.leftBarContainer}>
        <div className={styles.profileInfoContainer}>
          <ProfilePicture src={authStore.user?.photo || ''}/>
          <div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <p style={{ margin: 0, color: '#FFFFFF', fontSize: 24, lineBreak: 'anywhere' }}>{authStore.user?.name}</p>
          </div>
        </div>
        <div>
          <MenuButton text="History" fullWidth icon={<HistoryIcon />} isActive={currPage === 0} onClick={() => setCurrPage(0)}/>
          <MenuButton text="My Myths" fullWidth icon={<BookmarkIcon />} style={{ marginBottom: '20px' }} isActive={currPage === 1} onClick={() => setCurrPage(1)}/>

          <MenuButton text="Personal information" fullWidth icon={<PieChartIcon />} isActive={currPage === 2} onClick={() => setCurrPage(2)}/>
          <MenuButton text="Security" fullWidth icon={<LockIcon />} isActive={currPage === 3} onClick={() => setCurrPage(3)}/>
          <MenuButton text="Notifications" soon fullWidth icon={<BellNotificationIcon />} style={{ marginBottom: '20px' }} isActive={currPage === 4} onClick={() => setCurrPage(4)} disabled={true}/>

          <MenuButton text="Support" soon fullWidth icon={<CommentIcon />} isActive={currPage === 5} onClick={() => setCurrPage(5)} disabled={true}/>

          <div style={{ height: '1px', backgroundColor: '#0B4852', opacity: .5, margin: "20px 0px" }}></div>

          <MenuButton text="log out" fullWidth icon={<LogoutIcon />} onClick={() => setLogOutConfirm(true)}/>

          <MenuButton text="Delete account" soon fullWidth icon={<ProfileDeleteIcon />} disabled={true}/>
        </div>
      </div>
      <div style={{ flexGrow: 1, position: 'relative', display: 'inline-block'}}>
        <img src="/images/backgreoundFigureProfleHistory.png" className={styles.backgroundImageHistory}></img>
        {tabIndexToPage[currPage]}
      </div>
      <div className={styles.mainLogo}>
        <img src='/images/mainLogo.svg'></img>
      </div>
      <Modal isShow={logOutConfirm}>
        <LogOutConfirmModal onClose={() => setLogOutConfirm(false)}/>
      </Modal>
    </div>
  );
});
