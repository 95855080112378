import * as React from "react";
const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.6686 9.40004C23.3486 9.34671 23.0152 9.33339 22.6686 9.33339H9.33522C8.96189 9.33339 8.60189 9.36005 8.25522 9.41338C8.44189 9.04005 8.70855 8.69343 9.02855 8.37343L13.3619 4.02675C15.1886 2.21341 18.1486 2.21341 19.9752 4.02675L22.3086 6.38669C23.1619 7.22669 23.6152 8.29337 23.6686 9.40004Z"
      stroke="#FFDF76"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 25.3333C12 26.3333 11.72 27.28 11.2267 28.08C10.3067 29.6267 8.61334 30.6667 6.66668 30.6667C4.72001 30.6667 3.02668 29.6267 2.10668 28.08C1.61334 27.28 1.33334 26.3333 1.33334 25.3333C1.33334 22.3867 3.72001 20 6.66668 20C9.61334 20 12 22.3867 12 25.3333Z"
      stroke="#FFDF76"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.65564 25.3059H4.68231"
      stroke="#FFDF76"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66669 23.3594V27.346"
      stroke="#FFDF76"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.3333 15.9999V22.6666C29.3333 26.6666 26.6667 29.3333 22.6667 29.3333H10.1733C10.5867 28.9866 10.9467 28.5599 11.2267 28.0799C11.72 27.2799 12 26.3333 12 25.3333C12 22.3866 9.61332 19.9999 6.66666 19.9999C5.06666 19.9999 3.63999 20.7066 2.66666 21.8132V15.9999C2.66666 12.3733 4.85332 9.83992 8.25332 9.41325C8.59999 9.35992 8.95999 9.33325 9.33332 9.33325H22.6667C23.0133 9.33325 23.3467 9.34657 23.6667 9.3999C27.1067 9.7999 29.3333 12.3466 29.3333 15.9999Z"
      stroke="#FFDF76"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.3333 16.6667H25.3333C23.8667 16.6667 22.6667 17.8667 22.6667 19.3334C22.6667 20.8001 23.8667 22.0001 25.3333 22.0001H29.3333"
      stroke="#FFDF76"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SVGComponent;