import React from "react";

function CommentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        d="M12.375 13.5a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM7.875 13.5a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM16.875 13.5a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z"
      ></path>
      <path
        stroke="#fff"
        strokeWidth="2"
        d="M3.225 16.946a1.02 1.02 0 00-.023-.754 9.754 9.754 0 01-.82-5.81c.664-4.068 3.963-7.36 8.023-8.004 3.166-.504 6.266.497 8.503 2.742 2.237 2.246 3.224 5.348 2.708 8.514-.647 3.975-3.871 7.246-7.84 7.955-.601.107-1.2.161-1.793.161a9.62 9.62 0 01-4.946-1.364c-.18-.107-.462-.22-.778-.17l-2.318.385a1.24 1.24 0 01-1.156-.42 1.24 1.24 0 01-.235-1.207l.675-2.028z"
      ></path>
    </svg>
  );
}

export default CommentIcon;
