import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import styles from './RegistrationLoginModal.module.css';
import { useGoogleLogin } from '@react-oauth/google';
import CloseIcon from '../../app/icons/CloseIcon';
import { Input } from '../Input';
import { IconButton } from '../IconBtn';
import { Button, ButtonType } from '../Button';
import GoogleIcon from '../../app/icons/GoogleIcon';
import { useStores } from '../../stores/app';
import { PasswordChecker } from '../PasswordChecker';

interface RegistrationLoginModalProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClose?: () => void;
}

export const pasCheck = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\s]+$/

export const RegistrationLoginModal: React.FC<RegistrationLoginModalProps> = observer((props) => {
  const { authStore } = useStores();
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [termsAndConditions, setTermsAndConditions] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);
  const [passwordStrength, setPasswordStrength] = React.useState(0);

  const [errName, setErrName] = React.useState('');
  const [errEmail, setErrEmail] = React.useState('');

  useEffect(() => {
    if (authStore.isLoggedIn && props.onClose) props.onClose();
  }, [authStore.isLoggedIn]);

  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => authStore.loginByGoogle(tokenResponse.code),
    flow: 'auth-code',
  });

  const onChangeName = (name: string) => {
    name = name.replace(/\s\s+/g, ' ');

    if (name.length < 5) setErrName('Name must be at least 5 characters long.');
    else setErrName('');

    if (/^\d+$/.test(name)) setErrName('Name must be not only digits.')
    setName(name);
  }

  const onChangeEmail = (email: string) => {
    if (String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
        setErrEmail("");
      } else {
        setErrEmail("Write a valid email address.");
      }
    setEmail(email);
  }

  const onPasswordChange = (password: string) => {
    password = password.replace(/\s+/g, '');

    setPassword(password);
  }

  const openForgotPassword = () => {
    if (props.onClose) props.onClose();
    authStore.toggleShowPassword();
  }

  const submit = () => {
    if (authStore.isLogin) {
      authStore.loginByPassword(email, password, rememberMe);
    } else {
      authStore.createAccount(email, password, name);
      authStore.showLogin();
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <p className={styles.modalTitle}>{authStore.isLogin ? "Login to account" : "Signup to account"}</p>
        <CloseIcon className={styles.crossIcon} onClick={props.onClose}/>
      </div>
      <div className={styles.inputsContainer}>
        {!authStore.isLogin ? <Input placeHolder='Name' errorMsg={errName} value={name} onChange={(e) => onChangeName(e.target.value)} keyj="1"/> : <div style={{ height: "58px" }}/>}
        <Input placeHolder='Email' errorMsg={errEmail} value={email} onChange={(e) => onChangeEmail(e.target.value)} keyj="2"/>
        <Input placeHolder='Password' type='password' value={password} onChange={(e) => onPasswordChange(e.target.value)}/>
      </div>
      {authStore.isLogin ? 
      <div>
        <div className={styles.rememberMeContainer}>
          <div>
            <Input type='checkbox' checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)}/>
            <p>Remember me</p>
          </div>
          <p onClick={openForgotPassword}>Forgot password</p>
        </div>
      </div> :
      <div>
        <div style={{ margin: "0px 70px"}}>
          <PasswordChecker password={password} onPasswordStrengthChange={strength => setPasswordStrength(strength)} style={{ margin: "0px" }}/>
        </div>
        <div className={styles.checkContainer}>
          <Input type='checkbox' checked={termsAndConditions} onChange={(e) => setTermsAndConditions(e.target.checked)}/>
          <p>I’m agree with</p>
          <p>Terms & Conditions</p>
        </div>
      </div>
      }
      
      <div className={styles.btnContainer}>
        <IconButton size='medium' btnStyle={ButtonType.outline} icon={<GoogleIcon/>} onClick={() => googleLogin()}/>
        {authStore.isLogin ?
        <Button onClick={submit} text={"Log in"} btnStyle={ButtonType.bright} disabled={!(password.length >= 5 && email.length >= 5)}/> :
        <Button onClick={submit} text={"Sign up"} btnStyle={ButtonType.bright} disabled={!((termsAndConditions) && (authStore.isLogin || name.length >= 5) && email.length >= 5 && passwordStrength === 3 && !errEmail)}/>}
      </div>
      <div className={styles.linkContainer}>
        <p>{authStore.isLogin ? "Don't have an account?" : "Have an account?"}</p>
        <p onClick={() => {authStore.isLogin ? authStore.showRegistration() : authStore.showLogin()}}>{authStore.isLogin ? "Sign up now" : "Log in now"}</p>
      </div>
    </div>
  );
});