import React from 'react';
import {Navigate, Outlet} from 'react-router';
import {observer} from 'mobx-react';
import {Loading} from './loading';
import { useStores } from '../../stores/app';

export const AuthLayout = observer(() => {
  const {authStore, isLoading} = useStores();

  if (authStore.user) {
    return <Navigate to="/main" />;
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          width: '100%',
          flexWrap: 'wrap',
        }}
      >
        <Outlet />
      </div>
      <Loading isLoading={isLoading} />
    </>
  );
});
