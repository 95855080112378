import React from 'react';
import styles from './ProfilePicture.module.css';

interface ProfilePictureProps {
  src: string | JSX.Element;
}

const defaultProfileUrl = '/images/defaultAvatar.svg';

export const ProfilePicture: React.FC<ProfilePictureProps> = ({ src }) => {
  return (
    <div className={styles.container}>
      {typeof src === 'string' ? (
      <img src={src || defaultProfileUrl} className={styles.frame} onError={({ currentTarget }) => {
      currentTarget.onerror = null;
      currentTarget.src=defaultProfileUrl;
    }}></img>) : (src)}
      <img src='/images/profileFrame.svg' className={styles.profilePicture}></img>
    </div>
  );
};