import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import styles from './HeaderRoutLabel.module.css';

interface HeaderRoutLabelProps {
  label: string;
  redirectTo: string;
  currPath: string;
}

export const HeaderRoutLabel: React.FC<HeaderRoutLabelProps> = observer((props) => {
  const navigate = useNavigate();
  return (
    <p onClick={() => navigate(props.redirectTo)} className={`${styles.labelDefault} ${props.currPath === props.redirectTo ? styles.labelSelected : '' }`}>{props.label}</p>
  );
});