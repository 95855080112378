import * as React from "react";
const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.7958 3H3.79999C2.69542 3 1.79999 3.89543 1.79999 5V19C1.79999 20.1046 2.69542 21 3.79999 21H10.8"
      stroke="#21D9F7"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0071 6.79289C15.6166 6.40237 14.9834 6.40237 14.5929 6.79289C14.2024 7.18342 14.2024 7.81658 14.5929 8.20711L17.3819 10.9961H6.79999C6.2477 10.9961 5.79999 11.4438 5.79999 11.9961C5.79999 12.5484 6.2477 12.9961 6.79999 12.9961H17.3897L14.5929 15.7929C14.2024 16.1834 14.2024 16.8166 14.5929 17.2071C14.9834 17.5976 15.6166 17.5976 16.0071 17.2071L20.5071 12.7071C20.7029 12.5113 20.8006 12.2544 20.8 11.9977C20.8 11.9972 20.8 11.9966 20.8 11.9961C20.8 11.6931 20.6653 11.4216 20.4525 11.2383L16.0071 6.79289Z"
      fill="#21D9F7"
    />
  </svg>
);
export default SVGComponent;
