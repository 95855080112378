import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../stores/app';
import { useGoogleLogin } from '@react-oauth/google';
import { redirect } from 'react-router';


export const Login = observer(() => {
  const { authStore } = useStores();
  const [rememberMe, setRememberMe] = useState(false);
  const [password, setPassword] = useState("");
  const [login, setLogin] = useState("");

  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => authStore.loginByGoogle(tokenResponse.code),
    flow: 'auth-code',
  });

  if (authStore.isLoggedIn) {
    redirect('/proifle');
  }

  return (
      <form onSubmit={(e) => {
        e.preventDefault();
        setRememberMe(rememberMe);
        authStore.loginByPassword(login, password, rememberMe)
        }}>
        <>Email:</>
        <input type='text' onChange={e => setLogin(e.target.value)}/>
        <br/>
        <>Password:</>
        <input type='password' onChange={e => setPassword(e.target.value)}/>
        <br/>
        <>Remember me: </>
        <input type='checkbox' onChange={e => setRememberMe(e.target.checked)}/>
        <br/>
        <button type="button" style={{ marginRight: '10px' }} onClick={() => googleLogin()}>Login By Google</button>
        <button type='submit'>Login</button>
      </form>
  );
});
