import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
// import { useStores } from '../../stores/app';
import styles from './about.module.css';
import { Button, ButtonType } from '../../components/Button';
import EcoGlampingIcon from '../../app/icons/about/EcoGlampingIcon';
import AboutMythsIcon from '../../app/icons/about/AboutMythsIcon';
import LegendsArIcon from '../../app/icons/about/LegendsArIcon';
import OxeTokenIcon from '../../app/icons/about/OxeTokenIcon';
import { FaqSection } from '../../components/FAQComponent';
import { SEASON_START_IN } from '../../core';
import { useNavigate } from 'react-router-dom';
import { useStores } from '../../stores/app';

export const About = observer(() => {
  const { authStore } = useStores();
  const [seasonStartTime, setSeasonStartTime] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setSeasonStartTime((new Date(SEASON_START_IN).getTime() - new Date().getTime()) / 1000);

    const timer = setInterval(() => {
      setSeasonStartTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);
  }, []);

  return (
    <div className={styles.aboutContainer}>
      <img src="/images/mainLogoSquare.png" className={styles.aboutlogo}></img>

      <div className={styles.countDownContainer}>
        <p>Season Starts in</p>
        <div className={styles.countDown}><h6>{Math.floor(seasonStartTime / 604800)}</h6><p>weeks</p><h5>:</h5><h6>{Math.floor((seasonStartTime % (3600*24*7)) / (3600*24))}</h6><p>days</p><h5>:</h5><h6>{Math.floor((seasonStartTime % (3600*24)) / 3600)}</h6><p>hours</p><h5>:</h5><h6>{Math.floor((seasonStartTime % 3600) / 60)}</h6><p>minutes</p><h5>:</h5><h6>{Math.floor(seasonStartTime % 60)}</h6><p>seconds</p></div>
        <Button style={{ fontFamily: 'Anthu', fontSize: '20px' }} text='Participate' btnStyle={ButtonType.bright} onClick={() => authStore.user ? navigate('/profile?page=myMyths') : authStore.showRegistration()}/>
        <div className={styles.countDownBg}/>
      </div>

      <div className={styles.wahtIsMythopiaContainer}>
        <h2>What is Mythtopia?</h2>
        <p>Mythtopia is an Eco-glamping resort near Edinburgh, Scotland. A natural, mythological land of epic adventure and Celtic folklore. Explore an Augmented realm of Celtic mythology and natural beauty.</p>
      </div>

      <div className={styles.cardsContainer}>
        <div className={styles.aboutCard}>
          <EcoGlampingIcon/>
          <h3>Eco-glamping</h3>
          <p>A carbon-neutral resort with low-impact eco-domes and lodges powered through solar, wind, and geothermally</p>
          <span>Book now</span>
        </div>
        <div className={styles.aboutCard}>
          <AboutMythsIcon/>
          <h3>Myths</h3>
          <p>Acquire one of the 10k Myths that unlock your unique bonuses in the fascinating world of Mythtopia</p>
          <span>Get a Myth</span>
        </div>
        <div className={styles.aboutCard}>
          <LegendsArIcon/>
          <h3>Legends AR</h3>
          <p>Explore a parallel world behind the Myths. Adventure across Mythtopia and encounter magical mythological creatures to discover the story of Mythtopia and save our world.</p>
          <span>Explore</span>
        </div>
        <div className={styles.aboutCard}>
          <OxeTokenIcon/>
          <h3>OXE Token</h3>
          <p>Help with he reforestation of our lands, plant trees and help re-wild the land of Mythtopia to earn OXEGEN (OXE tokens)</p>
          <span>Buy</span>
        </div>
      </div>

      <div className={styles.aboytMythsMore}>
        <img src="/images/aboutMythMore.png"/>
        <h1>Myths</h1>
        <p>Become the owner of one or several unique Myths that provide their benefits in the Mythtopia system.</p>
        <Button text='Read more' btnStyle={ButtonType.bright}>
          <img src="/images/getAMythButterfly.png" className={styles.aboutButterfly}></img>
        </Button>
      </div>

      <div className={styles.aboytLegendsMore}>
        <img className={styles.elfImg} src="/images/whatisLegendsBg.png"/>
        <img className={styles.startBackground} src="/images/starWhatIsLegends.png"/>
        <h1>Legends are born and made</h1>
        <p>Dwarves, Elves, and Humans coexist peacefully with the forest’s flora and fauna of Mythtopia. Join them to stand against the dark entity - The Shadow - and protect the Crystal of Hope, a powerful gemstone from ancient times.</p>
        <Button text='Explore' btnStyle={ButtonType.bright}>
          <img src="/images/getAMythButterfly.png" className={styles.exploreButterfly}></img>
        </Button>
      </div>

      <div className={styles.roadMapContainer}>
        <h1>Roadmap</h1>
        <div>
          <div>
            <h3>Q4 2023</h3>
            <h5>Mythtopia concept and team formed</h5>
          </div>
          <div>
            <h3>Q1 2024</h3>
            <h5>Myth NFT launch</h5>
          </div>
          <div>
            <h3>Q2 2024</h3>
            <h5>First property acquired</h5>
          </div>
          <div>
            <h3>Q3 2024</h3>
            <h5>Buildout of property #1</h5>
          </div>
          <div>
            <h3>Q4 2024</h3>
            <h5>Property #1 first phase buildout</h5>
          </div>
        </div>
        <img src="/images/roadmapBG.png"/>
      </div>

      <div className={styles.weInMediaContainer}>
        <h1>We in media</h1>
        <div>
          <div><img src="/images/BdailyNews.png"/></div>
          <div><img src="/images/FinTech.png"/></div>
          <div><img src="/images/nftNewToday.png"/></div>
        </div>
      </div>

      <FaqSection/>
    </div>
  );
});
