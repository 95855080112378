import React from 'react';
import { observer } from 'mobx-react';
import styles from './ResetPasswordModal.module.css';
import CloseIcon from '../../app/icons/CloseIcon';
import { Input } from '../Input';
import { Button, ButtonType } from '../Button';
import { useStores } from '../../stores/app';

interface ResetPasswordModalProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClose?: () => void;
}

export const pasCheck = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\s]+$/

export const ResetPasswordModal: React.FC<ResetPasswordModalProps> = observer((props) => {
  const { authStore } = useStores();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const [errPassword, setErrPassword] = React.useState('');
  const [errEmail, setErrEmail] = React.useState('');

  const onChangeEmail = (email: string) => {
    if (String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
        setErrEmail("");
      } else {
        setErrEmail("Write a valid email address.");
      }
    setEmail(email);
  }

  const onPasswordChange = (password: string) => {
    password = password.replace(/\s+/g, '');
    let err = '';

    if (password.length < 8) err += 'Password must be at least 8 characters long\n';
    if (!pasCheck.test(password)) err += 'Password must contain at least one number, one uppercase letter, one lowercase letter.';

    if (err) setErrPassword(err);
    else setErrPassword('');

    setPassword(password);
  }

  const submit = () => {
    authStore.startResetPassword(password, email);
    authStore.toggleShowPassword();
  };

  return (
    <div className={styles.container}>
      <div>
        <p className={styles.modalTitle}>Reset password</p>
        <CloseIcon className={styles.crossIcon} onClick={props.onClose}/>
      </div>
      
      <div className={styles.inputsContainer}>
        <p>We will send you an email to reset your password</p>
      </div>

      <div className={styles.inputsContainer}>
        <Input placeHolder='Email' errorMsg={errEmail} value={email} onChange={(e) => onChangeEmail(e.target.value)} keyj="2"/>
        <Input placeHolder='New password' errorMsg={errPassword} value={password} onChange={(e) => onPasswordChange(e.target.value)}/>
      </div>
      
      <div className={styles.btnContainer}>
        <Button onClick={submit} text={"Submit"} btnStyle={ButtonType.bright} disabled={!!errPassword || !!errEmail || email.length == 0 || password.length === 0}/> 
        <Button onClick={props.onClose} text={"Cancel"} btnStyle={ButtonType.outline}/>
      </div>
    </div>
  );
});