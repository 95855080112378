import React from 'react';
import { observer } from 'mobx-react';
import { StakingList } from '../../../components/StakingList';
import { Button, ButtonType } from '../../../components/Button';
import styles from './pages.module.css';


export const HistoryPage = observer(() => {
  return (
    <div>
      <div className={styles.headerContainer}>
        <p>History</p>
      </div>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <Button text='Staking' btnStyle={ButtonType.outline}/>
        <Button text='Booking' style={{ marginLeft: '12px' }} disabled={true}/>
        <Button text='Orders' style={{ marginLeft: '12px' }} disabled={true}/>
      </div>
      <StakingList />
    </div>
  );
});