import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import styles from './pages.module.css';
import { Button, ButtonType } from '../../../components/Button';
import { useStores } from '../../../stores/app';
import { Input } from '../../../components/Input';
import { useSearchParams } from 'react-router-dom';
import ErrorInfoIcon from '../../../app/icons/errorInfoIcon';
import { Modal } from '../../../components/Modal';
import { DeletePhotoModal } from '../../../components/DeletePhotoModal';

const defaultProfileUrl = '/images/personalInfoDefaultAvatar.svg';
export const PersonalInformation = observer(() => {
  const { authStore, notificationStore } = useStores();
  const [searchParams] = useSearchParams();
  
  const hiddenFileInput = useRef(null);
  const [currSelectedProfile, setCurrSelectedProfile] = React.useState<File | null>();
  const [photoError, setPhotoError] = React.useState<string>("");

  const [editEmail, setEditEmail] = React.useState<boolean>(false);
  const [newEmail, setNewEmail] = React.useState<string>(authStore.user?.email || '');

  const [editName, setEditName] = React.useState<boolean>(false);
  const [newName, setNewName] = React.useState<string>(authStore.user?.name || '');

  const [editPhoneNumber, setEditPhoneNumber] = React.useState<boolean>(false);
  const [newPhonenumber, setNewPhonenumber] = React.useState<string>(authStore.user?.phoneNumber || '');
  const [errPhonenumber, setErrPhonenumber] = React.useState<string>('');

  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

  const saveChanges = () => {
    if ((currSelectedProfile) && !photoError) authStore.setUserImage(currSelectedProfile || null);
    if (editName) {
      authStore.updateUserInfo({ name: newName });
      setEditName(false);
    }

    if (authStore.user?.email && newEmail !== authStore.user.email) authStore.startResetEmail(newEmail);
    if (editPhoneNumber) {
      authStore.updateUserInfo({ phone: newPhonenumber });
      setEditPhoneNumber(false);
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const splitName = e.target.files[0].name.split(".");
      if (e.target.files[0].size > 800000)
        setPhotoError("Your image is larger than 8MB.")
      else if (!["jpg, jpeg, png"].includes(splitName[splitName.length - 1].toLowerCase()))
        setPhotoError("Please choose a smaller image in file format JPG or PNG.")
      else
        setPhotoError("");

      setCurrSelectedProfile(e.target.files[0]);
    }
  };

  const onChangePhoneNumber = (phoneNumber: string) => {
    if (phoneNumber.match(
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
    )) {
      setErrPhonenumber("");
    } else {
      setErrPhonenumber("Write a valid phone number.");
    }
    setNewPhonenumber(phoneNumber);
  }

  const getSelectedAvatar = () => {
    if (currSelectedProfile) {
      if (!currSelectedProfile) {
        return defaultProfileUrl;
      } else {
        return URL.createObjectURL(currSelectedProfile);
      }
    } else {
      return authStore.user?.photo || defaultProfileUrl;
    }
  }

  return (
    <div>
      <div className={styles.headerContainer}>
        <p>Personal Information</p>
        <Button text="Save changes" btnStyle={ButtonType.bright} style={{ fontWeight: 400 }} onClick={saveChanges}/>
      </div>
      <div>
        <div className={styles.profileImgContainer}>
          <img src={getSelectedAvatar()} onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = defaultProfileUrl;
          }}/>
          {(photoError || currSelectedProfile) && 
          <div className={styles.photoTextContainer}>
            <div>
              <p style={{ color: photoError ? "#C03838" : "#21D9F7" }}>{currSelectedProfile?.name}</p>
              {photoError && <ErrorInfoIcon style={{ width: 24, height: 24 }}/>}
            </div>
            <p>{photoError}</p>
          </div>}
          <Button text="Upload Photo" btnStyle={ButtonType.outline} onClick={() => (hiddenFileInput.current as HTMLInputElement | null)?.click()} style={{ marginRight: '10px', width: "244px" }}/>
          <Button text="Delete" btnStyle={ButtonType.outline} disabled={!authStore.user?.photo} onClick={() => {setShowDeleteModal(true);}}/>
          <input type='file' onChange={handleFileChange} accept="image/png, image/jpeg" ref={hiddenFileInput} style={{ display: 'none' }}></input>
        </div>
        <div className={styles.profileChangeInputsContainer}>
          <div>
            <div>
              <h6>Personal ID</h6>
              <p onClick={() => {
                navigator.clipboard.writeText(authStore.user?.id || "");
                notificationStore.enqueueSnackBar({
                  variant: 'default',
                  title: 'Your personal ID has been copied',
                  message: "",
                });
                }}>Copy</p>
            </div>
            <p>{authStore.user?.id}</p>
          </div>
          <div>
            <div>
              <h6>Name on the site</h6>
              <p onClick={() => setEditName(!editName)}>Edit</p>
            </div>
            {editName ? <Input value={newName} onChange={(e) => setNewName(e.target.value)} inpSize={'small'}/> : <p>{authStore.user?.name}</p>}
          </div>
        </div>

        <div className={styles.profileChangeInputsContainer}>
        <div>
            <div>
              <h6>Email</h6>
              <p onClick={() => setEditEmail(!editEmail)}>Edit</p>
            </div>
            {searchParams.get("email") === "changed" ? <h4 className={styles.message}>E-mail was successfully changed</h4> :
              authStore.isChangeEmailSend ? <h4 className={styles.message}>A confirmation letter has been sent to your e-mail</h4> :
              editEmail ? <Input value={newEmail} onChange={(e) => setNewEmail(e.target.value)} inpSize={'small'}/> : <p>{authStore.user?.email}</p>}
          </div>
          <div>
            <div>
              <h6>Phone number</h6>
              {authStore.user?.phoneNumber && <p onClick={() => setEditPhoneNumber(!editPhoneNumber)}>Edit</p>}
            </div>
            {editPhoneNumber ? <Input value={newPhonenumber} errorMsg={errPhonenumber} onChange={(e) => onChangePhoneNumber(e.target.value)} inpSize={'small'}/> : authStore.user?.phoneNumber ? <p>{authStore.user?.phoneNumber}</p> : <Button text='Add phone' btnStyle={ButtonType.outline} onClick={() => setEditPhoneNumber(true)}/>}
          </div>
        </div>
      </div>
      <Modal isShow={showDeleteModal}>
        <DeletePhotoModal onClose={() => setShowDeleteModal(false)}/>
      </Modal>
    </div>
  );
});
