import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, ButtonType } from '../../../components/Button';
import styles from './pages.module.css';
import { useStores } from '../../../stores/app';
import MoreVerticalIcon from '../../../app/icons/MoreVerticalIcon';
import { useNavigate } from 'react-router';
import { Tooltip } from 'react-tooltip';
import { Modal } from '../../../components/Modal';
import { GiftToFriendModal } from '../../../components/GiftToFriendModal';
import { ShowAttributesModal } from '../../../components/ShowAttributesModal';

export const MyMyths = observer(() => {
  const { tokenStore } = useStores();
  const navigate = useNavigate();

  const [isShowGiftToFriendPopup, setIsShowGiftToFriendPopup] = React.useState<number | null>(null);
  const [isShowAttributesPopup, setisShowAttributesPopup] = React.useState<number | null>(null);

  useEffect(() => {
    if (tokenStore.myTokens.length === 0) tokenStore.getMyTokens();
  }, []);

  return (
    <div>
      <div className={styles.headerContainer}>
        <p>My Myths</p>
        <Button text='Get a Myth' btnStyle={ButtonType.bright} onClick={() => navigate('/getMyth?page=howToGet')}/>
      </div>
      <div className={styles.tokenContainer}>
        {tokenStore.myTokens.map((token) => (
          <div key={token.id} className={styles.tokenCard}>
            <img src={token.photo}/>
            <div className={styles.tooltipContainer}>
              <h3>{`Myth #${token.id.toString().padStart(5, '0')}`}</h3>
              <MoreVerticalIcon id={`moreBtn-${token.id}`}/>
              <Tooltip className={styles.popupMoreMenu} anchorSelect={`#moreBtn-${token.id}`} closeEvents={{ blur: true }} place="top" openOnClick clickable>
                <p className={styles.popupMoreMenuDisable}>Share (work in progress)</p>
                <p onClick={() => setIsShowGiftToFriendPopup(token.id)}>Gift to a friend</p>
                <p onClick={() => setisShowAttributesPopup(token.id)}>More info about attributes</p>
                <p className={styles.popupMoreMenuDisable}>Mint (work in progress)</p>
              </Tooltip>
            </div>
            <div>
              <p>Rarity</p>
              <p>In Progress...</p>
            </div>
            <div>
              <Button text="Book now" btnStyle={ButtonType.bright}/>
              <Button text="Stake" btnStyle={ButtonType.bright}/>
            </div>
          </div>
        ))}
      </div>
      <Modal isShow={!!isShowGiftToFriendPopup}>
        <GiftToFriendModal onClose={() => setIsShowGiftToFriendPopup(null)} tokenId={isShowGiftToFriendPopup || 0}/>
      </Modal>
      <Modal isShow={!!isShowAttributesPopup}>
        <ShowAttributesModal onClose={() => setisShowAttributesPopup(null)} tokenId={isShowAttributesPopup || 0}/>
      </Modal>
    </div>
  );
});
