import * as React from "react";
const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0572 27.6095C10.5365 27.0888 10.5365 26.2446 11.0572 25.7239L20.781 16L11.0572 6.27614C10.5365 5.75544 10.5365 4.91122 11.0572 4.39052C11.5779 3.86982 12.4221 3.86982 12.9428 4.39052L23.6095 15.0572C24.1302 15.5779 24.1302 16.4221 23.6095 16.9428L12.9428 27.6095C12.4221 28.1302 11.5779 28.1302 11.0572 27.6095Z"
      fill="black"
    />
  </svg>
);
export default SVGComponent;