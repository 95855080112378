import React from 'react';

interface Props {
  isLoading: boolean;
}

export const Loading: React.FC<Props> = ({isLoading}) => {

  if (!isLoading) {
    return null;
  }

  return (
    <div>
      Loading...
    </div>
  );
};
