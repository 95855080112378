import * as React from "react";
const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.36987 30.5507L29.4078 10.5127"
      stroke="#FFDF76"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6258 15.5372C12.7071 15.5372 14.3943 13.85 14.3943 11.7687C14.3943 9.6873 12.7071 8 10.6258 8C8.54442 8 6.85712 9.6873 6.85712 11.7687C6.85712 13.85 8.54442 15.5372 10.6258 15.5372Z"
      stroke="#FFDF76"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.6639 33.0629C32.7453 33.0629 34.4326 31.3755 34.4326 29.2942C34.4326 27.2128 32.7453 25.5256 30.6639 25.5256C28.5826 25.5256 26.8954 27.2128 26.8954 29.2942C26.8954 31.3755 28.5826 33.0629 30.6639 33.0629Z"
      stroke="#FFDF76"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SVGComponent;