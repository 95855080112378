import * as React from "react";
const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.425 3.95261C20.5381 3.70407 20.4074 3.38087 20.1338 3.23207L16.28 1.13767C16.1925 1.08967 16.096 1.06567 16 1.06567C15.904 1.06567 15.808 1.08967 15.72 1.13767L11.8608 3.23527C11.5968 3.37874 11.4661 3.69234 11.5685 3.93607C11.6282 4.07954 11.7408 4.18567 11.8848 4.23581C12.0288 4.28701 12.1818 4.27474 12.3168 4.20221L15.7461 2.33874C15.9056 2.25234 16.096 2.25234 16.2554 2.33874L19.6853 4.20221C19.9514 4.34674 20.3008 4.22834 20.4261 3.95207L20.425 3.95261Z"
      fill="#FFDF76"
      stroke="#FFDF76"
      strokeWidth={0.5}
    />
    <path
      d="M19.192 13.1617L16.2544 14.7574C16.0949 14.8438 15.9045 14.8438 15.7451 14.7574L12.8075 13.1617C12.7275 13.118 12.6405 13.0972 12.5541 13.0972C12.3653 13.0972 12.1808 13.198 12.0843 13.3761C11.9435 13.6342 12.04 13.9596 12.2992 14.1004L15.1872 15.6694C15.3589 15.7628 15.4656 15.943 15.4656 16.1382V19.4774C15.4656 19.7718 15.7045 20.0113 15.9989 20.0113C16.2933 20.0113 16.5323 19.7718 16.5323 19.4774V16.1382C16.5323 15.943 16.6389 15.7628 16.8107 15.6694L19.6992 14.0998C19.9579 13.959 20.0544 13.6342 19.9136 13.3761C19.7739 13.1185 19.4485 13.0225 19.1909 13.1617H19.192Z"
      fill="#FFDF76"
      stroke="#FFDF76"
      strokeWidth={0.5}
    />
    <path
      d="M12.2997 29.0032L15.72 30.8619C15.8949 30.9579 16.1045 30.9579 16.2805 30.8619L19.7008 29.0032C19.8389 28.928 19.936 28.7968 19.9685 28.6427C20.0016 28.487 19.9643 28.3302 19.8645 28.2011C19.7115 28.0038 19.3931 27.9552 19.1403 28.0934L17.3221 29.0816C17.1563 29.1707 16.9563 29.1675 16.7952 29.071C16.6331 28.975 16.5344 28.8006 16.5344 28.6128V26.4902C16.5344 26.2032 16.3413 25.9467 16.0949 25.9072C15.936 25.8806 15.7776 25.9227 15.656 26.0262C15.5365 26.1275 15.4677 26.2763 15.4677 26.4331V28.6134C15.4677 28.8016 15.3685 28.976 15.2069 29.0715C15.0453 29.168 14.8453 29.1718 14.68 29.0822L12.8619 28.0939C12.7643 28.0406 12.6576 28.0155 12.5541 28.0155C12.3883 28.0155 12.232 28.0806 12.1376 28.2027C12.0373 28.3312 12.0005 28.488 12.0336 28.6438C12.0661 28.7979 12.1632 28.9291 12.3013 29.0043L12.2997 29.0032Z"
      fill="#FFDF76"
      stroke="#FFDF76"
      strokeWidth={0.5}
    />
    <path
      d="M29.3333 23.5174V18.9809C29.3333 18.8235 29.2645 18.6753 29.1445 18.5734C29.0235 18.471 28.8667 18.4289 28.7067 18.4545C28.4597 18.4945 28.2667 18.751 28.2667 19.0379V22.8171C28.2667 23.0123 28.16 23.1926 27.9883 23.2859L24.9477 24.9377C24.6741 25.0859 24.5435 25.4097 24.6565 25.6582C24.7813 25.9339 25.1301 26.0528 25.3979 25.9083L29.1125 23.8902C29.2491 23.8161 29.3339 23.6737 29.3339 23.5179L29.3333 23.5174Z"
      fill="#FFDF76"
      stroke="#FFDF76"
      strokeWidth={0.5}
    />
    <path
      d="M29.112 8.11038L25.3968 6.09225C25.2635 6.01971 25.1099 6.00745 24.9653 6.05811C24.8213 6.10878 24.7099 6.21491 24.6496 6.35731C24.5472 6.60211 24.6789 6.91731 24.9435 7.06078L26.8197 8.07945C26.9915 8.17278 27.0981 8.35251 27.0981 8.54825C27.0981 8.74398 26.9915 8.92318 26.8197 9.01705L25.1552 9.92158C24.8827 10.0693 24.7536 10.3914 24.8672 10.64C24.9925 10.9162 25.3413 11.0357 25.6085 10.8906L27.4789 9.87411C27.5584 9.83091 27.6459 9.80958 27.7339 9.80958C27.8283 9.80958 27.9227 9.83465 28.0064 9.88478C28.1685 9.98078 28.2672 10.1552 28.2672 10.3429V12.9626C28.2672 13.2496 28.4603 13.5061 28.7072 13.5461C28.8677 13.5733 29.0245 13.5306 29.1451 13.4272C29.2651 13.3258 29.3339 13.177 29.3339 13.0197V8.48318C29.3339 8.32745 29.2491 8.18451 29.1125 8.11091L29.112 8.11038Z"
      fill="#FFDF76"
      stroke="#FFDF76"
      strokeWidth={0.5}
    />
    <path
      d="M2.88798 23.8897L6.60318 25.9078C6.86931 26.0523 7.21918 25.9334 7.34344 25.6582C7.45704 25.4091 7.32638 25.0859 7.05278 24.9371L4.01224 23.2854C3.84051 23.1921 3.73384 23.0118 3.73384 22.8166V19.0374C3.73384 18.7505 3.54078 18.4939 3.29384 18.4539C3.26238 18.4486 3.23144 18.4459 3.20051 18.4459C3.07358 18.4459 2.95304 18.4897 2.85598 18.5723C2.73598 18.6737 2.66718 18.8225 2.66718 18.9798V23.5163C2.66718 23.6721 2.75198 23.815 2.88851 23.8886L2.88798 23.8897Z"
      fill="#FFDF76"
      stroke="#FFDF76"
      strokeWidth={0.5}
    />
    <path
      d="M6.85869 6.04168C6.75575 6.04168 6.64855 6.06675 6.55202 6.11955L2.88802 8.10995C2.75149 8.18408 2.66669 8.32648 2.66669 8.48222V12.9612C2.66669 13.2481 2.85975 13.5046 3.10669 13.5446C3.26829 13.5718 3.42402 13.5292 3.54455 13.4257C3.66455 13.3244 3.73335 13.1756 3.73335 13.0182V10.3409C3.73335 10.1526 3.83255 9.97822 3.99415 9.88275C4.15575 9.78568 4.35575 9.78248 4.52109 9.87208L6.39202 10.8886C6.65869 11.0332 7.00749 10.9142 7.13282 10.6385C7.24589 10.39 7.11522 10.0668 6.84162 9.91795L5.18029 9.01502C5.00855 8.92115 4.90189 8.74142 4.90189 8.54622C4.90189 8.35102 5.00855 8.17075 5.18029 8.07742L7.11149 7.02835C7.24962 6.95315 7.34669 6.82195 7.37975 6.66835C7.41229 6.51262 7.37495 6.35582 7.27522 6.22622C7.18082 6.10408 7.02402 6.03955 6.85922 6.03955L6.85869 6.04168Z"
      fill="#FFDF76"
      stroke="#FFDF76"
      strokeWidth={0.5}
    />
  </svg>
);
export default SVGComponent;