/* eslint-disable */
export const IS_STAGE = (window['runConfig' as any] as any)?.IS_STAGE || false;
export const IS_LOCALHOST = (window['runConfig' as any] as any)?.IS_LOCALHOST || false;

export const BASE_URL =
  (window['runConfig' as any] as any)?.BASE_URL ||
  'https://mythopia-backend-mythopia.medevelop.studio';
  // 'http://0.0.0.0:3000';

export const MINIO_URL = 
  (window['runConfig' as any] as any)?.MINIO_URL ||
  'https://minio-stage.medevelop.studio:9001/'

  export const SEASON_START_IN = 
  (window['runConfig' as any] as any)?.SEASON_START_IN ||
  '10.1.2024';