import * as React from "react";
const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_194_3004)">
      <path
        d="M5.31891 14.5034L4.4835 17.6221L1.43011 17.6867C0.517594 15.9942 0 14.0577 0 11.9999C0 10.01 0.483938 8.1335 1.34175 6.4812H1.34241L4.06078 6.97958L5.25159 9.68164C5.00236 10.4082 4.86652 11.1882 4.86652 11.9999C4.86661 12.8808 5.02617 13.7247 5.31891 14.5034Z"
        fill="#FBBB00"
      />
      <path
        d="M23.7903 9.7583C23.9281 10.4842 24 11.2339 24 12.0001C24 12.8592 23.9097 13.6972 23.7376 14.5056C23.1534 17.2563 21.627 19.6583 19.5126 21.3581L19.5119 21.3574L16.088 21.1827L15.6034 18.1576C17.0064 17.3348 18.1029 16.0471 18.6805 14.5056H12.2638V9.7583H18.7741H23.7903Z"
        fill="#518EF8"
      />
      <path
        d="M19.5119 21.3575L19.5125 21.3581C17.4561 23.0111 14.8437 24.0001 12 24.0001C7.43012 24.0001 3.45694 21.4458 1.43011 17.6869L5.31891 14.5037C6.3323 17.2083 8.94132 19.1336 12 19.1336C13.3147 19.1336 14.5464 18.7781 15.6033 18.1577L19.5119 21.3575Z"
        fill="#28B446"
      />
      <path
        d="M19.6596 2.76262L15.7721 5.94525C14.6783 5.26153 13.3853 4.86656 12 4.86656C8.87208 4.86656 6.21427 6.88017 5.25164 9.68175L1.34241 6.48131H1.34175C3.33891 2.63077 7.36219 0 12 0C14.9116 0 17.5813 1.03716 19.6596 2.76262Z"
        fill="#F14336"
      />
    </g>
    <defs>
      <clipPath id="clip0_194_3004">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SVGComponent;