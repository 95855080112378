import React, { ReactNode, useEffect } from 'react';
import styles from './Modal.module.css';
import { observer } from 'mobx-react';

interface ModalProps {
  isShow?: boolean;
  children?: ReactNode;
}

export const Modal: React.FC<ModalProps> = observer((props) => {

  useEffect(() => {
    document.body.style.overflow = props.isShow ? 'hidden' : 'auto';
  }, [props.isShow]);

  if (!props.isShow) return null;

  return (
    <>
      <div className={styles.background}/>
      <div className={styles.mainModalWindow}>
        {props.children}
      </div>
    </>
  );
});