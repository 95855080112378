import React from "react";

function HistoryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g
        stroke="#21D9F7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_289_1461)"
      >
        <path d="M12 8v4l2 2"></path>
        <path d="M3.05 11a9 9 0 11.5 4m-.5 5v-5h5"></path>
      </g>
      <defs>
        <clipPath id="clip0_289_1461">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default HistoryIcon;
