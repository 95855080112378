import React from "react";

function ProfileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect
        width="25.267"
        height="25.267"
        x="3.367"
        y="3.367"
        stroke="#F7F7F7"
        strokeWidth="1.4"
        rx="12.633"
      ></rect>
      <path
        stroke="#F7F7F7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
        d="M16.104 14.37a1.184 1.184 0 00-.215 0 2.878 2.878 0 01-2.778-2.885 2.888 2.888 0 115.778 0 2.888 2.888 0 01-2.785 2.885zM12.415 17.896c-1.793 1.2-1.793 3.156 0 4.348 2.037 1.363 5.377 1.363 7.414 0 1.793-1.2 1.793-3.155 0-4.348-2.03-1.355-5.37-1.355-7.415 0z"
      ></path>
    </svg>
  );
}

export default ProfileIcon;
