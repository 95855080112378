import React from 'react';
import { makeAutoObservable } from 'mobx';
import { 
  NotificationStore,
  AuthStore,
  PaymentStore,
 } from '..';
import { TokenStore } from '../token';

export class AppStore {
  isLoading = false;

  authStore = new AuthStore(this);
  notificationStore = new NotificationStore(this);
  paymentStore = new PaymentStore(this);
  tokenStore = new TokenStore(this);
  constructor() {
    makeAutoObservable(this);
  }
}

const AppStoreContext = React.createContext(new AppStore());

export const useStores = () => React.useContext(AppStoreContext);
