import { makeAutoObservable } from 'mobx';
import { BaseError, Routes } from '@medevelop-studio/mythopia-contract-lib';
import { Static } from '@sinclair/typebox';
import { AppStore } from '../app';
import axios, { AxiosResponse } from 'axios';
import { BASE_URL } from '../../core';
import { errorToString } from '../auth';

export class PaymentStore {
  public error: BaseError | null = null;

  public isSendRequest = false;
  public walletAdressToPay = "";

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *buyByStripe() {
    this.isSendRequest = true;
    try {
      const resp: AxiosResponse<Static<typeof Routes.mythopia.payment.getBuyUrl.responseType>> = yield axios.post<{token: string}>(BASE_URL + "/token/buy", {
        accessToken: this.rootStore.authStore.accessToken,
      });

      window.open(resp.data.buyUrl, '_blank');

      this.rootStore.isLoading = true;
      this.error = null;
    } catch (err) {
      this.isSendRequest = false;
      this.error = err as BaseError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: errorToString(err),
        variant: 'error',
      });
    }

    this.isSendRequest = false;
    this.rootStore.isLoading = false;
  }

  *buyByCrypto() {
    this.isSendRequest = true;
    try {
      const resp: AxiosResponse<Static<typeof Routes.mythopia.payment.getWalletAdress.responseType>> = yield axios.post<{token: string}>(BASE_URL + "/token/cyptoBuy", {
        accessToken: this.rootStore.authStore.accessToken,
      });

      this.walletAdressToPay = resp.data.walletAddress;

      this.rootStore.isLoading = true;
      this.error = null;
    } catch (err) {
      this.isSendRequest = false;
      this.error = err as BaseError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: errorToString(err),
        variant: 'error',
      });
    }

    this.isSendRequest = false;
    this.rootStore.isLoading = false;
  }
}
