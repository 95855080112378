import React from 'react';
import styles from './MenuButton.module.css';

interface MenuButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isActive?: boolean;
  text?: string;
  fullWidth?: boolean;
  icon?: JSX.Element;
  disabled?: boolean;
  soon?: boolean;
}

export const MenuButton: React.FC<MenuButtonProps> = (props) => {
  return (
    <button className={`
      ${styles.defaultBtn}
      ${props.fullWidth ? styles.fullWidth : ''}
      ${props.isActive ? styles.defaultBtnActive : ''}
      ${props.disabled ? styles.defaultBtnDisabled : ''}
    `} {...props}>
      <div></div>
      {props.icon}
      <p>{props.text}</p>
      {props.soon && <div className={styles.soonLabel}>soon</div>}
      </button>
  );
};