import React from "react";

function PieChartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#21D9F7"
        strokeWidth="2"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
        clipRule="evenodd"
      ></path>
      <path stroke="#21D9F7" strokeWidth="2" d="M12 2v10m0 10V12m0 0h10"></path>
    </svg>
  );
}

export default PieChartIcon;
