import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
// import { useStores } from '../../stores/app';
import styles from './getMyth.module.css';
import { Button, ButtonType } from '../../components/Button';
import { ProfilePicture } from '../../components/profilePicture';
import PercentageIcon from '../../app/icons/PercentageIcon';
import CalendarIcon from '../../app/icons/CalendarIcon';
import WalletIcon from '../../app/icons/WalletIcon';
import CubeIcon from '../../app/icons/CubeIcon';
import BuyCryptoIcon from '../../app/icons/BuyCryptoIcon';
import MainComponentIcon from '../../app/icons/MainComponentIcon';
import OBJECTS from '../../app/icons/objects/OBJECTS';
import OBJECTS4 from '../../app/icons/objects/OBJECTS4';
import OBJECTS2 from '../../app/icons/objects/OBJECTS2';
import OBJECTS3 from '../../app/icons/objects/OBJECTS3';
import { useStores } from '../../stores/app';
import { Input } from '../../components/Input';
import CopyIcon from '../../app/icons/CopyIcon';
import { IconButton } from '../../components/IconBtn';
import LeftArrowIcon from '../../app/icons/LeftArrowIcon';
import RightArrowIcon from '../../app/icons/RightArrowIcon';
import OBJECTS5 from '../../app/icons/objects/OBJECTS5';
import { FaqSection } from '../../components/FAQComponent';
import { useSearchParams } from 'react-router-dom';

export const GetMyth = observer(() => {
  const { authStore, paymentStore } = useStores();
  const [searchParams] = useSearchParams();

  const photosHorizontalScroll = useRef<HTMLDivElement>(null);
  const [buyWithCard, setBuyWithCard] = React.useState(false);
  const [userNewEmail, setUserNewEmail] = React.useState(authStore.user?.email || '');

  let lastScroll = 0;

  useEffect(() => {
    if (searchParams.get("page") === "howToGet") document.getElementById('howToGet')?.scrollIntoView();
  }, []);

  useEffect(() => {
    setUserNewEmail(authStore.user?.email || '');
  }, [authStore.user]);

  const scroll = (scrollOffset: number) => {
    if ((new Date().getTime() - lastScroll) / 1000 > .5) {
      lastScroll = new Date().getTime();
      photosHorizontalScroll.current?.scrollBy({ left: scrollOffset, behavior: 'smooth' })
    }
  };

  return (
    <div>
      <img src="/images/getMythFirstBackground.png" className={styles.backgroundFirst}></img>

      <div className={styles.titleScreen}>
        <h1>MYTHS</h1>
        <h6>Acquire your Myth and relive the magic</h6>
        <Button text='Get a Myth' btnStyle={ButtonType.bright} onClick={() => document.getElementById('howToGet')?.scrollIntoView()}>
          <img src="/images/getAMythButterfly.png" className={styles.getAMythButterfly}></img>
        </Button>
      </div>

      <div className={styles.keyPrivilegesContainer}>
        <h1>Key privileges</h1>
        <h6>Myth are access tokens to entry into the exclusive Mythtopia Resort and its immersive augmented reality game, Mythtopia Legends</h6>
        <div className={styles.keyPrivilegesContainerRow}>
          <div>
            <div>
              <ProfilePicture src={<PercentageIcon/>}/>
              <h3>Discount</h3>
            </div>
            <h6>Up to 20% discount on house rental when booking additional days, on food and merchandise</h6>
          </div>
          <div>
            <div>
              <ProfilePicture src={<CalendarIcon/>}/>
              <h3>2 nights stay</h3>
            </div>
            <h6>of eco-domes rental. Or days of awesome relaxation in a unique place without expenses. Up to 4 night stays available</h6>
          </div>
          <div>
            <div>
              <ProfilePicture src={<WalletIcon/>}/>
              <h3>Extra earnings</h3>
            </div>
            <h6>Receive bonus OXE and other benefits from staking your Myth during the December to January booking period. Earn bonuses even if you can&apos;t go</h6>
          </div>
        </div>
        <div className={styles.keyPrivilegesContainerRow}>
          <div>
            <div>
              <ProfilePicture src={<CubeIcon/>}/>
              <h3>AR items</h3>
            </div>
            <h6>You can receive extra in game assets like magical weapons, armor and spells</h6>
          </div>
          <div>
            <div>
              <ProfilePicture src={<BuyCryptoIcon/>}/>
              <h3>Extra OXEs</h3>
            </div>
            <h6>Opportunity to increase the number of your OXE tokens to 0.015</h6>
          </div>
          <div>
            <div>
              <ProfilePicture src={<MainComponentIcon/>}/>
              <h3>More bonuses</h3>
            </div>
            <h6>Also, you can get 3D figurines of characters, audiobooks, firewood for the fireplace, bonuses for activity in the game</h6>
          </div>
        </div>
      </div>

      <div className={styles.supplyContainer}>
        <div>
          <h3>10 000</h3>
          <h5>Total supply</h5>
        </div>
        <img src='/images/supplybacgkroundImg.png'/>
        <div>
          <h3>70 000</h3>
          <h5>{"Myths' attributes"}</h5>
        </div>
      </div>

      <div className={styles.whatAreMythsContainer}>
        <div>
          <h1>What are Myths?</h1>
          <p>Each Myth is a unique portal image that has certain attributes. When combined, these attributes provide the opportunity to receive real bonuses for active relaxation in Mythtopia Resort, additional tokens, discounts, and two free days of eco-dom rental. The size and type of bonuses directly depend on the attributes that will be depicted on the Myth.</p>
          <p>More about attributes</p>
        </div>
        <img src="/images/whatAreMythImg.png"/>
      </div>

      <div className={styles.howToGetAMythContainer} id="howToGet">
        <h1>How to get a Myth</h1>
        <div>
          <Button text='With bank card' onClick={() => setBuyWithCard(true)} btnStyle={buyWithCard ? ButtonType.outline : ButtonType.default}/>
          <Button text='With cryptocurrency' onClick={() => setBuyWithCard(false)} btnStyle={buyWithCard ? ButtonType.default : ButtonType.outline}/>
        </div>

        <div className={styles.howToStepsContainer}>
          <div className={styles.flexMe}>
            <h3>1</h3>
            <h3>Authorize on site</h3>
            <div><OBJECTS3 className={`${authStore.isLoggedIn ? styles.firstIconBlue : styles.firstIconRed}`}/></div>
            <div>
              <p>To start, please log in to the site: sign in to your account or create one if you’re visiting us for the first time. This is necessary to ensure that your Myths are easily accessible to you</p>
              {authStore.isLoggedIn ? (<div>
                <Input className={styles.smallInput} inpSize='small' value={userNewEmail} onChange={(e) => setUserNewEmail(e.target.value)}/>
                <p style={{ fontSize: '15px'}}>*your registered email</p>
              </div>) : (<div className={styles.btnContainer}>
                <Button text='Sign up' btnStyle={ButtonType.outline} onClick={() => authStore.showRegistration()}/>
                <Button text='Log in' btnStyle={ButtonType.bright} onClick={() => authStore.showLogin()}/>
              </div>)}
            </div>
          </div>
          <div className={`${buyWithCard ? styles.hideMe : styles.flexMe}`}>
            <h3>2</h3>
            <h3>Set up or open wallet</h3>
            <div><OBJECTS2/></div>
            <p>There are many Ethereum wallets, but the most popular one is MetaMask. MetaMask is a browser plugin that works best with Google Chrome or Brave. After installation, it allows you to store Ethereum and Ethereum-based tokens</p>
          </div>
          <div className={`${buyWithCard ? styles.hideMe : styles.flexMe}`}>
            <h3>3</h3>
            <h3>Buy Ethereum</h3>
            <div><OBJECTS4/></div>
            <p>You need Ethereum to enter the world of NFTs, as it is the main currency of the Ethereum network, and most NFTs are created on Ethereum. To get Ethereum, you need to go to a cryptocurrency exchange, such as Coinbase or Binance.</p>
          </div>
          <div className={`${buyWithCard ? styles.hideMe : styles.flexMe}`}>
            <h3>4</h3>
            <h3>Send Ethereum</h3>
            <div><OBJECTS5/></div>
            <div>
              <p>Generate a personal Ethereum address for 24 hours, during which you can pay for the Myth. Then copy the Ethereum crypto address and send the appropriate amount. And wait for the receipt of the Myth, it will appear in your personal cabinet.</p>
              {paymentStore.walletAdressToPay ? (<div className={styles.qrCodeContainer}>
                <img src="/images/cryptoBuyQrCode.png"/>
                <div className={styles.walletInput}>
                  <p>{paymentStore.walletAdressToPay}</p>
                  <IconButton fillPrimary size='medium' icon={<CopyIcon/>} onClick={() => navigator.clipboard.writeText(paymentStore.walletAdressToPay || "")}/>
                </div>
              </div>) : (<Button text='Generate address' disabled={!authStore.isLoggedIn} btnStyle={ButtonType.bright} onClick={() => paymentStore.buyByCrypto()}/>)}
            </div>
          </div>
          <div className={`${buyWithCard ? styles.hideMe : styles.flexMe}`}>
            <h3>5</h3>
            <h3>Add Myth to NFT collection</h3>
            <div><OBJECTS/></div>
            <p>For your Myth to appear in your NFT collection, go to your personal cabinet and press the “Mint” button. For further instructions, please contact technical support.</p>
          </div>

          <div className={`${!buyWithCard ? styles.hideMe : styles.flexMe}`}>
            <h3>2</h3>
            <h3>Payment</h3>
            <div><OBJECTS2/></div>
            <div>
              <p>Go to the payment page and follow the further instructions</p>
              <Button text='Get 1 Myth' disabled={!authStore.isLoggedIn} btnStyle={ButtonType.bright} onClick={() => paymentStore.buyByStripe()}/>
            </div>
          </div>
          <div className={`${!buyWithCard ? styles.hideMe : styles.flexMe}`}>
            <h3>3</h3>
            <h3>Get a Myth</h3>
            <div><OBJECTS4/></div>
            <p>After successful payment, your Myth will appear in your account</p>
          </div>
          <div className={`${styles.devider} ${authStore.isLoggedIn ? styles.deviderBlue : styles.deviderRed}`}/>
          <img className={styles.hoToGetBack} src="/images/howToGetMythBack.png"/>
        </div>
      </div>

      <div>
        <div className={styles.photoSamplesHeader}>
          <h1>Some samples</h1>
          <div>
            <IconButton icon={<LeftArrowIcon/>} onClick={() => scroll(-426.7)}/>
            <IconButton icon={<RightArrowIcon/>} onClick={() => scroll(426.7)}/>
          </div>
        </div>

        <div className={styles.photoSamplesContainer} ref={photosHorizontalScroll}>
            <div>
              <img src="/images/298.png"/>
              <h1>Myth #00001</h1>
            </div>
            <div>
              <img src="https://placehold.co/400x400"/>
              <h1>Myth #00001</h1>
            </div>
            <div>
              <img src="/images/298.png"/>
              <h1>Myth #00001</h1>
            </div>
            <div>
              <img src="https://placehold.co/400x400"/>
              <h1>Myth #00001</h1>
            </div>
            <div>
              <img src="/images/298.png"/>
              <h1>Myth #00001</h1>
            </div>
            <div>
              <img src="https://placehold.co/400x400"/>
              <h1>Myth #00001</h1>
            </div>
            <div>
              <img src="/images/298.png"/>
              <h1>Myth #00001</h1>
            </div>
            <div>
              <img src="https://placehold.co/400x400"/>
              <h1>Myth #00001</h1>
            </div>
            <div>
              <img src="/images/298.png"/>
              <h1>Myth #00001</h1>
            </div>
            <div>
              <img src="https://placehold.co/400x400"/>
              <h1>Myth #00001</h1>
            </div>
        </div>
      </div>

      <div className={styles.ecoGlampingContainer}>
        <div>
          <h1>Eco-glamping</h1>
          <h4>A carbon-neutral resort with low-impact eco-domes and lodges powered through solar, wind, and geothermally</h4>
          <Button text='Book now' btnStyle={ButtonType.bright}>
            <img src="/images/getAMythButterfly.png" className={styles.ecoGlampingButterfly}></img>
          </Button>
        </div>
        <img src="/images/eco-Glamping-on-getAMyth.png"></img>
      </div>

      <FaqSection/>
    </div>
  );
});
