import React from 'react';
import { observer } from 'mobx-react';
import styles from './Accordion.module.css';

interface AccordionProps {
  titleComponent?: React.ReactNode;
  children?: React.ReactNode;
}

export const Accordion: React.FC<AccordionProps> = observer((props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className={styles.listContainer}>
      <div onClick={() => setIsOpen(!isOpen)}>
        {props.titleComponent}
      </div>
      <div className={`${styles.childContained} ${isOpen ? styles.childContainedOpen : ''}`}>
        {props.children}
      </div>
    </div>
  );
});