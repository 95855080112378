import React from 'react';
import { observer } from 'mobx-react';
import styles from './GiftToFriendModal.module.css';
import CloseIcon from '../../app/icons/CloseIcon';
import { Input } from '../Input';
import { Button, ButtonType } from '../Button';
import { useStores } from '../../stores/app';

interface GiftToFriendModalProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClose?: () => void;
  tokenId: number;
}
export const GiftToFriendModal: React.FC<GiftToFriendModalProps> = observer((props) => {
  const { tokenStore } = useStores();
  const [email, setEmail] = React.useState('');
  const [errEmail, setErrEmail] = React.useState('');

  const submit = () => {
    tokenStore.giveToken(props.tokenId, email);
    if (props.onClose) props.onClose();
  }

  const onChangeEmail = (email: string) => {
    if (String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
        setErrEmail("");
      } else {
        setErrEmail("Write a valid email address.");
      }
    setEmail(email);
  }

  return (
    <div className={styles.container}>
      <div>
        <p className={styles.modalTitle}>Give myth</p>
        <CloseIcon className={styles.crossIcon} onClick={props.onClose}/>
      </div>
      <div className={styles.inputsContainer}>
        <p>You trying to give myth to other person. If you do this you will no longer have access to this myth.</p>
        <p>If you sure about that, write registration email of person wich you want to give this token, if not close this window.</p>
        <Input placeHolder='Email' errorMsg={errEmail} value={email} onChange={(e) => onChangeEmail(e.target.value)}/>
      </div>
      <div className={styles.btnContainer}>
        <Button onClick={props.onClose} text={"Cancel"} btnStyle={ButtonType.outline}/>
        <Button onClick={submit} text={"Confirm"} btnStyle={ButtonType.bright}/>
      </div>
      {/* 
      {authStore.isLogin ? 
      <div>
        <div className={styles.rememberMeContainer}>
          <div>
            <Input type='checkbox' checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)}/>
            <p>Remember me</p>
          </div>
          <p>Forgot password</p>
        </div>
      </div> :
      <div>
        <div className={styles.passwordStrength}>
          <div className={styles.passwordStrengthBar}>
            <p>Password strength: </p>
            <div style={{ backgroundColor: passwordStrength >= 1 ? strengthColors[passwordStrength] : strengthColors[0] }}/>
            <div style={{ backgroundColor: passwordStrength >= 2 ? strengthColors[passwordStrength] : strengthColors[0] }}/>
            <div style={{ backgroundColor: passwordStrength >= 3 ? strengthColors[passwordStrength] : strengthColors[0] }}/>
          </div>
          <div className={styles.passwordStates}>
            <div style={{ backgroundColor: password.length >= 8 ? strengthColors[3] : strengthColors[0] }}/>
            <p>Use at least 8 symbols</p>
          </div>
          <div className={styles.passwordStates}>
            <div style={{ backgroundColor: pasCheck.test(password) ? strengthColors[3] : strengthColors[0] }}/>
            <p>Use at least 1 letter and 1 number</p>
          </div>
        </div>
        <div className={styles.checkContainer}>
          <Input type='checkbox' checked={termsAndConditions} onChange={(e) => setTermsAndConditions(e.target.checked)}/>
          <p>I’m agree with</p>
          <p>Terms & Conditions</p>
        </div>
      </div>
      }
      
      <div className={styles.btnContainer}>
        <IconButton size='medium' btnStyle={ButtonType.outline} icon={<GoogleIcon/>} onClick={() => googleLogin()}/>
        {authStore.isLogin ?
        <Button onClick={submit} text={"Log in"} btnStyle={ButtonType.bright} disabled={!(password.length > 5 && email.length > 5)}/> :
        <Button onClick={submit} text={"Sign up"} btnStyle={ButtonType.bright} disabled={!((termsAndConditions) && (authStore.isLogin || name.length > 5) && email.length > 5 && passwordStrength === 3)}/>}
      </div>
      <div className={styles.linkContainer}>
        <p>{authStore.isLogin ? "Don't have an account?" : "Have an account?"}</p>
        <p onClick={() => {authStore.isLogin ? authStore.showRegistration() : authStore.showLogin()}}>{authStore.isLogin ? "Sign up now" : "Log in now"}</p>
      </div> */}
    </div>
  );
});